import React, { useEffect } from "react";
import { FaUser } from "react-icons/fa";
import { BiSolidLockAlt } from "react-icons/bi";
import { useForm } from "react-hook-form";
import { useStateProvider } from "../../context/StateReducers";
import { reducerCases } from "../../context/constants";
import { LoginAuth } from "../../services/auth.api";
import { useNavigate } from "react-router-dom";
import { toastMessage } from "../../components/toast/toastMessage";

import bgImg from "../../assets/sw-wall.png";

const Login = () => {
  const { register, handleSubmit } = useForm();
  const [{}, dispatch] = useStateProvider();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      dispatch({
        type: reducerCases.SET_USER_INFO,
        userInfo: JSON.parse(localStorage.getItem("userInfo")),
      });
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  }, []);

  const handleLogin = (payload) => {
    callApis(payload);
  };

  async function callApis(payload) {
    const { data } = await LoginAuth("/admin/login", payload);

    console.log({ data });

    if (data.success) {
      localStorage.setItem("accessToken", data.data.accessToken);
      localStorage.setItem("refreshToken", data.data.refreshToken);
      localStorage.setItem("userInfo", JSON.stringify(data.data));

      dispatch({
        type: reducerCases.SET_USER_INFO,
        userInfo: data.data,
      });
      toastMessage(data);
      navigate("/dashboard");
    } else {
      toastMessage(data);
    }
  }

  return (
    <div
      className="flex justify-center items-center h-screen w-screen"
      style={{
        backgroundImage: `url(${bgImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="w-[94%] h-[450px] sm:w-[400px] bg-white/[0.8] rounded-lg shadow-md">
        <div className="py-2 px-2 flex justify-center items-center w-full h-full">
          <form
            onSubmit={handleSubmit((data) => handleLogin(data))}
            className="space-y-4 px-4"
          >
            <h3 className="text-center font-bold text-xl md:text-3xl mb-4 uppercase">
              Salonwallah <br /> Admin
            </h3>
            <div className="border-b-2 border-[#EC0000] mb-9"></div>

            <div className="space-y-2 relative">
              <span className="text-lg">Email/Username</span>
              <span className="absolute top-10 left-2">
                <FaUser className="text-lg" />
              </span>
              <input
                {...register("email", { required: true })}
                className="w-full py-2 focus:outline-none bg-gray-100 pl-8 rounded-md shadow-lg text-md hover:bg-gray-200"
                type="email"
                placeholder="Enter email address?"
              />
            </div>
            <div className="space-y-2 relative">
              <span className="text-lg">Password</span>
              <span className="absolute top-10 left-2">
                <BiSolidLockAlt className="text-lg" />
              </span>

              <input
                {...register("password", { required: true })}
                className="w-full py-2 focus:outline-none bg-gray-100 pl-8 rounded-md shadow-lg text-md hover:bg-gray-200"
                type="password"
                placeholder="*************"
              />
            </div>

            <div className="space-y-2 flex justify-center py-4">
              <button
                type="submit"
                className="bg-sw-100 shadow-md text-lg font-bold cursor-pointer w-48 py-2 rounded-lg hover:bg-sw-100 text-white"
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
