import React, { useState } from "react";
import GalleryModel from "./GalleryModel";
import { FaSearch } from "react-icons/fa";
import { useStateProvider } from "../../context/StateReducers";
import { useEffect } from "react";
import { reducerCases } from "../../context/constants";
import { deleteRecord, getRecord } from "../../services/services.api";
import { toastMessage } from "../../components/toast/toastMessage";
import { AiOutlineFullscreen, AiOutlineMinusCircle } from "react-icons/ai";
import { FullScreen } from "../../components/form/ImageViewer";

const Gallery = () => {
  const [isToggle, setIsToggle] = useState(false);
  const [isFullScreen, setIsfullScreen] = useState(false);
  const [imageURL, setImageURL] = useState(null);
  const [{ gallery, userInfo, refresh, loadMore }, dispatch] =
    useStateProvider();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState("all");
  const tabs = [
    "all",
    "services",
    "category",
    "packages",
    "coupons",
    "blogs",
    "banners",
  ];

  useEffect(() => {
    callApis();
  }, [userInfo, refresh, loadMore, currentTab]);

  async function callApis() {
    if (userInfo) {
      setLoading(true);
      let params = {};
      params.limit = limit;
      params.page = page;
      if (currentTab !== "all") {
        if (currentTab === "services") {
          params.type = "service";
        }
        if (currentTab === "category") {
          params.type = "category";
        }
        if (currentTab === "packages") {
          params.type = "package";
        }
        if (currentTab === "coupons") {
          params.type = "coupon";
        }
        if (currentTab === "blogs") {
          params.type = "blog";
        }
        if (currentTab === "banners") {
          params.type = "banner";
        }
      }
      const { data } = await getRecord(
        "/admin/gallery/list",
        params,
        userInfo?.accessToken
      );

      if (data.success) {
        if (loadMore) {
          setLimit((prev) => prev + 5);
        }
        dispatch({
          type: reducerCases.GET_GELLARY,
          gallery: data.data.rows,
        });
        dispatch({ type: reducerCases.SET_REFRESH, refresh: false });
        dispatch({ type: reducerCases.LOAD_MORE, loadMore: false });
      }
    }
  }

  const handleDelete = (id) => {
    console.log({ id });
    (async function (id) {
      let params = {};
      const { data } = await deleteRecord(
        `admin/gallery/delete/${id}`,
        params,
        userInfo?.accessToken
      );
      if (data.success) {
        dispatch({ type: reducerCases.SET_REFRESH, refresh: true });
        toastMessage(data);
      }
    })(id);
  };

  return (
    <div className="px-4 h-full overflow-y-auto no-scrollbar">
      <div
        style={{
          backgroundImage: `url('/assets/sample-salon.jpeg')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="h-64 mt-2"
      >
        <div className="h-full bg-black/[0.8] flex flex-col justify-center items-center space-y-2">
          <div className="text-center">
            <h1 className="uppercase text-6xl font-semibold text-sw-100">
              SalonWalla Gallery
            </h1>
            <span className="capitalize text-xl font-semibold text-gray-400">
              we are a style brand
            </span>
          </div>
          <div className="w-[50%] flex items-center bg-gray-100 rounded-full p-2 shadow-md">
            {/* Dropdown */}
            <div className="relative">
              <select className="appearance-none bg-transparent outline-none font-medium text-gray-700 p-2 pr-8">
                <option value="photos">Photos</option>
                <option value="videos">Videos</option>
                <option value="icons">Icons</option>
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-600"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.23 7.2a.75.75 0 011.5 0v1.95H8.7a.75.75 0 010 1.5H6.73v2.15a.75.75 0 01-1.5 0v-2.15H3.77a.75.75 0 110-1.5H5.23V7.2z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>

            {/* Search Input */}
            <input
              type="text"
              placeholder="Search for free photos"
              className="bg-transparent w-full p-2 outline-none text-gray-700 placeholder-gray-400"
            />

            {/* Search Icon */}
            <button className="bg-transparent p-2 text-gray-600">
              <FaSearch />
            </button>
          </div>
        </div>
      </div>

      {/* Categories */}
      <div className="flex justify-between items-center">
        <div className="py-8 space-x-4 space-y-3">
          {tabs.map((tab) => (
            <button
              onClick={() => setCurrentTab(tab)}
              className={`px-12 py-2 rounded-full capitalize ${
                currentTab === tab
                  ? "bg-black text-white"
                  : "bg-slate-50 text-black hover:bg-slate-100"
              }  text-sm font-medium`}
            >
              {tab}
            </button>
          ))}
        </div>
        <div>
          <button
            onClick={() => setIsToggle(!isToggle)}
            className="bg-black px-8 text-white font-medium py-1 rounded-full"
          >
            Add
          </button>
        </div>
      </div>

      <div className="px-2 py-2">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {gallery &&
            gallery.map((gallery, idx) => (
              <div key={idx + 1} className="rounded overflow-hidden shadow-lg">
                {gallery?.dataFile?.url && (
                  <img
                    src={gallery?.dataFile?.url}
                    alt="gallery"
                    className="w-full h-auto object-cover"
                  />
                )}
              </div>
            ))}
        </div>
      </div>

      {isToggle && (
        <GalleryModel isToggle={isToggle} setIsToggle={setIsToggle} />
      )}

      {isFullScreen && (
        <FullScreen
          isFullScreen={isFullScreen}
          setIsfullScreen={setIsfullScreen}
          imageURL={imageURL}
        />
      )}
    </div>
  );
};

export default Gallery;
