import React, { useState } from "react";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import Styles from "./scrollbar.module.css";

function MyDatePicker({ selectedDate, setSelectedDate }) {
  return (
    <DateTimePicker
      className={`py-[1px] px-2 rounded-md text-sm shadow-md capitalize bg-white focus:outline-none hover:cursor-pointer ${Styles.reactDatetimePicker}`}
      onChange={(date) => setSelectedDate(date)}
      value={selectedDate}
    />
  );
}

export default MyDatePicker;
