import React, { useEffect, useState } from "react";
import styles from "./app.module.css";
import Sidebar from "../../components/Sidebar";
import { Outlet, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { useStateProvider } from "../../context/StateReducers";
import { reducerCases } from "../../context/constants";

const AppLayout = () => {
  const navigate = useNavigate();
  const [_, dispatch] = useStateProvider();
  const [isDrawer, setIsDrawer] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      navigate("/login");
    } else {
      dispatch({
        type: reducerCases.SET_USER_INFO,
        userInfo: JSON.parse(localStorage.getItem("userInfo")),
      });
    }
  }, []);

  return (
    <div className={`h-screen flex flex-col overflow-hidden`}>
      {/* Header Section */}
      <Header isDrawer={isDrawer} setIsDrawer={setIsDrawer} />

      {/* Main Content */}
      <div className="flex flex-1 mt-2 overflow-hidden">
        {/* Sidebar */}
        <div
          className={`w-[18%] ${
            isDrawer && "hidden"
          } bg-sw-100 rounded-tr-3xl overflow-y-auto ${
            styles.contentBoxScrollbar
          }`}
        >
          <Sidebar />
        </div>

        {/* Main Outlet Content */}
        <div className={`flex-1 overflow-auto ${styles.contentBoxScrollbar}`}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AppLayout;
