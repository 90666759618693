import React, { useEffect } from "react";
import { BiArrowBack, BiRupee } from "react-icons/bi";
import {
  MdCategory,
  MdDescription,
  MdDiscount,
  MdVerified,
} from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { getRecord, getServices } from "../../services/services.api";
import { useStateProvider } from "../../context/StateReducers";
import { reducerCases } from "../../context/constants";
import { toastMessage } from "../../components/toast/toastMessage";
import { calculateTime } from "../../utils/dateUtils";
import { GiDuration } from "react-icons/gi";
import { IoPricetags, IoTimeSharp } from "react-icons/io5";

const CouponsView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [{ userInfo, coupon }, dispatch] = useStateProvider();

  useEffect(() => {
    if (userInfo) {
      callApi();
    }
  }, [userInfo]);

  const callApi = async () => {
    const query = {};
    const { data } = await getRecord(
      `/coupons/view/${id}`,
      query,
      userInfo?.accessToken
    );

    console.log({ data });

    if (data.success) {
      dispatch({ type: reducerCases.VIEW_COUPON, coupon: data?.data });
    } else {
      toastMessage(data);
    }
  };

  return (
    <div className="p-2">
      <div className="flex items-center justify-start space-x-4 border-b-2 py-3">
        <span onClick={() => navigate(-1)}>
          <BiArrowBack className="text-lg cursor-pointer font-bold" />
        </span>
        <p className="text-xs font-medium">
          <span className="bg-red-600 px-2 py-1 text-white rounded-lg capitalize">
            Coupon
          </span>
          {" / "}
          <span className="bg-red-600 px-2 py-1 text-white rounded-lg capitalize">
            {coupon?.code}
          </span>
        </p>
      </div>

      <div className="mt-2">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          <div className="md:aspect-square col-span-1 w-full flex justify-center align-center">
            <div className=" rounded-lg w-full flex flex-col p-1 justify-center align-center shadow-md ring-1 ring-gray-100">
              {coupon?.images !== null ? (
                <img
                  className="rounded-lg flex justify-center align-center p-3"
                  src={coupon?.images}
                  alt="icon"
                />
              ) : (
                <span className="text-lg font-bold h-fit text-center tracking-widest">
                  COUPON
                </span>
              )}
            </div>
          </div>

          <div className="col-span-1 md:col-span-3 w-full md:flex-1">
            <div className="flex items-center space-x-2">
              <span>
                {coupon?.status === "active" && (
                  <MdVerified className="text-green-600 font-bold text-lg" />
                )}
              </span>
              <p className="text-lg font-semibold capitalize">
                {coupon?.name} {"< "}
                <span className="text-sm text-sw-100">{coupon?.type}</span>
                {" >"}
              </p>
            </div>

            <div className="pb-1 flex items-center space-x-2">
              <span>
                <MdCategory className="text-lg" />
              </span>
              <span className="text-gray-700 font-semibold text-lg">
                Code :{" "}
              </span>
              <span className="text-gray-500 capitalize text-md">
                {coupon?.code}
              </span>
            </div>

            <div className="pb-1 flex items-center space-x-2">
              <span>
                <BiRupee className="text-lg" />
              </span>
              <span className="text-gray-700 font-semibold text-lg">
                Price : Rs
              </span>
              <span className="text-gray-500 capitalize text-md">
                {coupon?.price}
              </span>
            </div>

            <div className="pb-1 flex items-center space-x-2">
              <span>
                <MdDiscount className="text-lg" />
              </span>
              <span className="text-gray-700 font-semibold text-lg">
                Discount :{" "}
              </span>
              <span className="text-gray-500 capitalize text-md">
                {coupon?.discount}%
              </span>
            </div>

            <div className="pb-1 flex items-center space-x-2">
              <span>
                <IoTimeSharp className="text-lg" />
              </span>
              <span className="text-gray-700 font-semibold text-lg">
                Created On :{" "}
              </span>
              <span className="text-gray-500 capitalize text-md">
                {calculateTime(coupon?.createdAt)}
              </span>
            </div>

            <div className="pb-1 flex items-center space-x-2">
              <span>
                <IoTimeSharp className="text-lg" />
              </span>
              <span className="text-gray-700 font-semibold text-lg">
                Last Updated On :{" "}
              </span>
              <span className="text-gray-500 capitalize text-md">
                {calculateTime(coupon?.updatedAt)}
              </span>
            </div>

            <div className="pb-1 flex items-center space-x-2">
              <span>
                <GiDuration className="text-lg" />
              </span>
              <span className="text-gray-700 font-semibold text-lg">
                Valid Until :{" "}
              </span>
              <span className="text-gray-500 capitalize text-md">
                {calculateTime(coupon?.validUntil)}
              </span>
            </div>

            <div className="pb-1 flex items-center space-x-2">
              <span>
                <IoPricetags className="text-lg" />
              </span>
              <span className="text-gray-700 font-semibold text-lg">
                Booking Apply Range : Rs
              </span>
              <span className="text-gray-500 capitalize text-md">
                {coupon?.bookingApplyRanges}
              </span>
            </div>

            <div className="flex items-center space-x-2">
              <span>
                <MdDescription className="text-lg" />
              </span>
              <span className="text-lg font-medium text-gray-700">
                Description{" "}
              </span>
            </div>

            <div className="pl-4 w-[92%]">
              <p className="text-sm font-normal w-fit text-gray-600 capitalize px-1 rounded-md bg-slate-200">
                {coupon?.desciption}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CouponsView;
