import React, { useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { FiEdit, FiPlusCircle } from "react-icons/fi";
import { BsFillEyeFill } from "react-icons/bs";
import styles from "../services/services.module.css";
import { deleteRecord, getRecord } from "../../services/services.api";
import { useStateProvider } from "../../context/StateReducers";
import { reducerCases } from "../../context/constants";
import { toastMessage } from "../../components/toast/toastMessage";
import { Link } from "react-router-dom";
import { calculateTime } from "../../utils/dateUtils";
import Pagination from "../../components/form/Pagination";
import PackageModel from "./PackageModel";

const PackagesList = () => {
  const [isToggle, setIsToggle] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(null);
  const [totalPages, setTotalPages] = useState([]);
  const [editItem, setEditItem] = useState(null);

  const [{ refresh, userInfo, packages }, dispatch] = useStateProvider();

  useEffect(() => {
    !isToggle && setEditItem(null);
  }, [isToggle]);

  useEffect(() => {
    callApis();
  }, [page, limit, userInfo, refresh]);

  async function callApis() {
    if (userInfo) {
      let params = {};
      params.limit = limit;
      params.page = page;
      const { data } = await getRecord(
        "/admin/packages/admin/list/admin",
        params,
        userInfo?.accessToken
      );

      if (data.success) {
        dispatch({
          type: reducerCases.SET_PACKAGES,
          packages: data?.data?.rows,
        });
        setCurrentPage(data.data.currentPage);
        setTotalPages(
          Array.from({ length: data.data.totalPages }, (_, index) => index + 1)
        );
        dispatch({ type: reducerCases.SET_REFRESH, refresh: false });
      }
    }
  }

  const handleDelete = (category) => {
    (async function (category) {
      let params = {};
      const { data } = await deleteRecord(
        `/admin/services/category/delete/${category}`,
        params,
        userInfo?.accessToken
      );

      if (data.success) {
        dispatch({ type: reducerCases.SET_REFRESH, refresh: true });
        toastMessage(data);
      }
    })(category);
  };

  const editHandleClick = (id) => {
    (async function (id) {
      const query = {};
      const { data } = await getRecord(
        `/admin/packages/view/${id}/admin`,
        query,
        userInfo?.accessToken
      );

      if (data.success) {
        setIsToggle(!isToggle);
        setEditItem(data?.data);
      } else {
        toastMessage(data);
      }
    })(id);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  return (
    <div className="px-4 h-full overflow-y-auto no-scrollbar">
      <h1 className="border-b py-2 font-medium text-lg text-gray-600">
        Packages
      </h1>
      <div className="flex justify-between my-4">
        <div>
          <h1>Filter:</h1>
        </div>
        <div className="flex items-center space-x-2">
          <div>
            <input
              type="text"
              className="bg-gray-100 focus-within:outline-none py-2 px-2 rounded-lg"
              placeholder="Search..."
            />
          </div>
          <button
            onClick={() => setIsToggle(!isToggle)}
            className="flex items-center bg-sw-100 px-4 py-2 rounded-2xl space-x-2 text-white"
          >
            <FiPlusCircle />
            <span>Add</span>
          </button>
        </div>
      </div>

      {/* Table Wrapper with Horizontal Scroll */}
      <div className={`w-full overflow-x-auto ${styles.tableScrollbar}`}>
        <table className="min-w-full text-sm text-left text-gray-500">
          <thead class="text-xs text-white uppercase bg-sw-100/[0.8]">
            <tr>
              <th scope="col" className="px-6 py-3">
                S.No
              </th>
              <th scope="col" className="px-6  py-3">
                Shop Type
              </th>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6  py-3">
                Service Type
              </th>
              <th scope="col" className="px-6 py-3">
                description
              </th>
              <th scope="col" className="px-6 py-3">
                Services
              </th>
              <th scope="col" className="px-6 py-3">
                ExpiredOn
              </th>
              <th scope="col" className="px-6 py-3">
                Price
              </th>
              <th scope="col" className="px-6 py-3">
                offerPrice
              </th>
              <th scope="col" className="px-6 py-3">
                CreatedAt
              </th>
              <th scope="col" className="px-6 py-3">
                Status
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {packages &&
              packages?.map((item, index) => (
                <tr
                  class={`capitalize ${
                    (index + 1) % 2 == 0 ? "bg-gray-100" : "bg-gray-50"
                  }`}
                  key={index + 1}
                >
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                  >
                    <div className="w-[50px]">{index + 1}</div>
                  </th>
                  <td className="px-6 py-4">
                    <div className="w-[100px]">{item?.type}</div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="w-48">{item?.name}</div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="w-48">
                      <p className="capitalize">{item?.packageTypes}</p>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="w-[300px] line-clamp-3">
                      <p className="capitalize">{item?.description}</p>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="w-[220px]">
                      <ul className="w-full list-disc space-y-1">
                        {item?.services.map((serv) => (
                          <li className="capitalize">{serv?.name}</li>
                        ))}
                      </ul>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div>{calculateTime(item?.expiredOn)}</div>
                  </td>
                  <td className="px-6 py-4">
                    <div>
                      <span> Rs.{item?.price}</span>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div>
                      <span>Rs.{item?.offerPrice ? item?.offerPrice : 0}</span>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div>
                      <span>{calculateTime(item?.createdAt)}</span>
                    </div>
                  </td>
                  <td className="px-6 py-4 text-green-700 first-letter:uppercase">
                    {item.status}
                  </td>

                  <td className="px-6 py- space-x-4">
                    <div className="flex space-x-2">
                      <button
                        onClick={() => editHandleClick(item?.id)}
                        class="font-medium"
                      >
                        <FiEdit className="text-md" />
                      </button>
                      <Link to={`/packages/${item?.id}`} class="font-medium">
                        <BsFillEyeFill className="text-md" />
                      </Link>
                      <button
                        onClick={() => handleDelete(item?.id)}
                        class="font-medium text-sw-100"
                      >
                        <AiFillDelete className="text-md" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <div>
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          limit={limit}
          setLimit={setLimit}
          handlePageChange={handlePageChange}
        />
      </div>

      {isToggle && (
        <PackageModel
          isToggle={isToggle}
          setIsToggle={setIsToggle}
          editItem={editItem}
        />
      )}
    </div>
  );
};

export default PackagesList;
