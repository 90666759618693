import React, { useState } from "react";
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";

const Pagination = ({
  totalPages,
  currentPage,
  limit,
  setLimit,
  handlePageChange,
}) => {
  const getVisiblePages = () => {
    const pages = [];
    const lastPagesStart = totalPages.length - 2;

    // Add the first few pages: current and next 3
    for (
      let i = currentPage;
      i <= Math.min(currentPage + 2, totalPages.length);
      i++
    ) {
      pages.push(i);
    }

    // Add '...' if there is a gap between next 3 pages and last pages
    if (currentPage + 3 < lastPagesStart) {
      pages.push("...");
    }

    // Add the last 3 pages
    for (
      let i = Math.max(lastPagesStart, currentPage + 3);
      i <= totalPages.length;
      i++
    ) {
      pages.push(i);
    }

    return pages;
  };

  console.log({ totalPages });

  return (
    <div className="py-6">
      <div className="flex justify-between">
        <div>
          <h1>Pagination:</h1>
        </div>
        <div className="flex items-center space-x-4">
          <div className="flex items-center space-x-2">
            <label className="text-gray-700">Rows per page:</label>
            <select
              value={limit}
              onChange={(e) => setLimit(Number(e.target.value))}
              className=" text-gray-700 rounded-md py-1 px-4 focus:outline-none"
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </select>
          </div>

          {/* Pagination Buttons */}
          <div className="flex items-center space-x-2">
            {/* Prev Buttons */}
            <button
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
              className="px-3 py-2 rounded text-teal-500 hover:bg-gray-200 disabled:text-gray-300"
            >
              <MdKeyboardDoubleArrowLeft />
            </button>
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="px-3 py-2 rounded text-teal-500 hover:bg-gray-200 disabled:text-gray-300"
            >
              <MdKeyboardArrowLeft />
            </button>

            {/* Page Numbers */}
            {totalPages.length > 4
              ? getVisiblePages().map((page, idx) => (
                  <button
                    key={idx}
                    onClick={() =>
                      typeof page === "number" && handlePageChange(page)
                    }
                    className={`w-9 h-9 rounded-full flex items-center justify-center font-semibold ${
                      currentPage === page
                        ? "bg-teal-500 text-white"
                        : "text-gray-600 hover:bg-gray-200"
                    }`}
                    disabled={page === "..."}
                  >
                    {page}
                  </button>
                ))
              : totalPages.map((page, idx) => (
                  <button
                    key={idx}
                    onClick={() => handlePageChange(page)}
                    className={`w-9 h-9 rounded-full flex items-center justify-center font-semibold ${
                      currentPage === page
                        ? "bg-teal-500 text-white"
                        : "text-gray-600 hover:bg-gray-200"
                    }`}
                  >
                    {page}
                  </button>
                ))}

            {/* Next Buttons */}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages.length}
              className="px-3 py-2 rounded text-teal-500 hover:bg-gray-200 disabled:text-gray-300"
            >
              <MdKeyboardArrowRight />
            </button>
            <button
              onClick={() => handlePageChange(totalPages.length)}
              disabled={currentPage === totalPages.length}
              className="px-3 py-2 rounded text-teal-500 hover:bg-gray-200 disabled:text-gray-300"
            >
              <MdKeyboardDoubleArrowRight />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
