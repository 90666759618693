import React, { useEffect, useState } from "react";
import bgImg from "../../assets/sample-salon.jpeg";
import { useStateProvider } from "../../context/StateReducers";
import { useNavigate } from "react-router-dom";
import { FiPower } from "react-icons/fi";
import { PiNotePencilLight } from "react-icons/pi";
import ChangePasswordForm from "../../components/form/ChangePasswordForm";
import { reducerCases } from "../../context/constants";

const Settings = () => {
  const [{}, dispatch] = useStateProvider();
  const navigate = useNavigate();
  const [profileState, setProfileState] = useState({
    role: "ADMIN",
    firstName: "vivek",
    lastName: "kumar",
    email: "admin@gmail.com",
    phone: "9121327733",
    isBlock: false,
    status: "unverified",
    createdAt: "2023-09-21T18:00:09.229Z",
    password: "12345678",
    image: bgImg,
  });

  const [passwordFormState, setPasswordFormState] = useState({
    new_pwd: "",
    confirm_new_pwd: "",
  });

  const handleNewPasswordInputChange = (e) => {
    console.log(passwordFormState);
    e.preventDefault();
  };

  const handleConfirmNewPasswordInputChange = (e) => {
    console.log(passwordFormState);
    e.preventDefault();
  };

  const handlePasswordFormSubmit = (e) => {
    if (passwordFormState.new_pwd === passwordFormState.confirm_new_pwd) {
      //simply changes the password for now
      setProfileState({ ...profileState, password: passwordFormState.new_pwd });
    }

    console.log(profileState);
    e.preventDefault();
  };

  useEffect(() => {}, [profileState, passwordFormState]);

  return (
    <div className="px-4 h-full">
      <div className="py-2">
        <div
          className="h-64 rounded-lg shadow-md relative overflow-hidden"
          style={{
            backgroundImage: `url('/assets/sample-salon.jpeg')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="h-full bg-black/[0.8] flex flex-col justify-center items-center space-y-2">
            <button
              className="bg-slate-50 text-dark font-medium p-2 rounded-full shadow-lg hover:shadow-2xl border-4 border-red-600 absolute right-2 top-2"
              onClick={() => {
                localStorage.removeItem("accessToken");
                localStorage.removeItem("refreshToken");
                localStorage.removeItem("userInfo");
                dispatch({
                  type: reducerCases.SET_USER_INFO,
                  userInfo: undefined,
                });
                navigate("/login");
              }}
            >
              <FiPower />
            </button>

            <div className="text-center">
              <h1 className="uppercase text-8xl font-semibold text-sw-100">
                SalonWalla
              </h1>
              <span className="capitalize text-2xl font-semibold text-gray-400">
                We are fashion and makeover brand
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="shadow-md py-4 px-2 rounded-lg bg-slate-50">
        <div className="w-36 h-36 rounded-full relative cursor-pointer mt-4">
          <img
            src={"/assets/blog-sample.png"}
            height={"100%"}
            loading="lazy"
            className="bg-slate-300 rounded-full h-full"
          />
          <button className="absolute -right-2 bottom-4">
            <PiNotePencilLight className="text-3xl text-sw-100" />
          </button>
        </div>
      </div>

      <div className="grid grid-cols-3 mt-4">
        <ChangePasswordForm />
      </div>
    </div>
  );
};

export default Settings;
