import React, { useEffect } from "react";
import { BiArrowBack } from "react-icons/bi";
import { MdVerified } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { getServices } from "../../services/services.api";
import { useStateProvider } from "../../context/StateReducers";
import { reducerCases } from "../../context/constants";
import { toastMessage } from "../../components/toast/toastMessage";
import { calculateTime } from "../../utils/dateUtils";

const CategoryView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [{ userInfo, category }, dispatch] = useStateProvider();

  useEffect(() => {
    if (userInfo) {
      callApi();
    }
  }, [userInfo]);

  const callApi = async () => {
    const query = {};
    const { data } = await getServices(
      `/admin/services/category/view/${id}/admin`,
      query,
      userInfo?.accessToken
    );

    if (data.success) {
      dispatch({ type: reducerCases.VIEW_CATEGORY, category: data?.data });
    } else {
      toastMessage(data);
    }
  };

  return (
    <div className="px-2 py-2">
      <div className="flex items-center justify-start space-x-4 border-b-2 py-3">
        <span onClick={() => navigate(-1)}>
          <BiArrowBack className="text-lg cursor-pointer font-bold" />
        </span>
        <p className="text-xs font-medium">
          <span className="bg-red-600 px-2 py-1 text-white rounded-lg capitalize">
            Category
          </span>
          {" / "}
          <span className="bg-red-600 px-2 py-1 text-white rounded-lg capitalize">
            {category?.name}
          </span>
        </p>
      </div>

      <div className="mt-1 md:mt-3">
        <div className=" flex flex-col md:flex-row justify-between gap-x-4">
          <div className="w-[50%] p-5 md:p-0 md:w-[15%] lg:w-[8%]">
            <img
              className="rounded-lg shadow-md px-3 py-3 ring-1 ring-gray-200 w-full "
              src={category?.icon}
              alt="icon"
            />
          </div>
          <div className="w-full md:w-[calc(100%-15%)] lg:w-[calc(100%-8%)] px-5 md:px-0">
            <div className="flex items-center space-x-2">
              <span>
                {category?.status === "active" && (
                  <MdVerified className="text-green-600 font-bold text-lg" />
                )}
              </span>
              <p className="text-lg font-semibold">
                {category?.name} {"< "}
                <span className="text-sm text-sw-100 capitalize">
                  {category?.type}
                </span>
                {" >"}
              </p>
            </div>

            <div className="w-full md:w-[80%] lg:w-[50%]">
              <p className="text-sm font-normal w-fit text-gray-600 capitalize p-1 rounded-md bg-slate-200">
                {category?.description}
              </p>
              <p className="py-4 text-sm text-gray-500">
                <span className="text-lg pl-2 font-medium text-gray-700">
                  Created At :{" "}
                </span>{" "}
                <span className="pl-1">
                  {calculateTime(category?.createdAt)}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryView;
