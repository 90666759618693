import React, { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import styles from "../services/services.module.css";
import ServicesModel from "../../components/models/ServicesModel";

import { FaUsers } from "react-icons/fa";
import { HiMiniBriefcase } from "react-icons/hi2";
import { FaSackDollar } from "react-icons/fa6";
import { useStateProvider } from "../../context/StateReducers";
import { AddRecord, getServices } from "../../services/services.api";
import { reducerCases } from "../../context/constants";
import Pagination from "../../components/form/Pagination";
import { calculateTime } from "../../utils/dateUtils";
import { toastMessage } from "../../components/toast/toastMessage";

const Requests = () => {
  const [card, setCard] = useState([
    {
      name: "Total Requests",
      icon: <FaUsers className="text-5xl w-full text-sw-100" />,
      count: 100,
    },
    {
      name: "Approved Requests",
      icon: <HiMiniBriefcase className="text-5xl w-full text-sw-100" />,
      count: 200,
    },
    {
      name: "Reject Requests",
      icon: <FaSackDollar className="text-5xl w-full text-sw-100" />,
      count: 201,
    },
  ]);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(null);
  const [totalPages, setTotalPages] = useState([]);

  const [{ userInfo, withdrawal_requests, refresh }, dispatch] =
    useStateProvider();

  useEffect(() => {
    callApis();
  }, [page, limit, userInfo, refresh]);

  async function callApis() {
    if (userInfo) {
      let params = {};
      params.limit = limit;
      params.page = page;
      const { data } = await getServices(
        "/admin/payouts/requests",
        params,
        userInfo?.accessToken
      );

      if (data.success) {
        dispatch({
          type: reducerCases.GET_WITHDRAWAL_REQUESTS,
          withdrawal_requests: data.data.rows,
        });
        setCurrentPage(data.data.currentPage);
        setTotalPages(
          Array.from({ length: data.data.totalPages }, (_, index) => index + 1)
        );
        dispatch({ type: reducerCases.SET_REFRESH, refresh: false });
      }
    }
  }

  const handlePayoutApproved = (id) => {
    (async function (id) {
      const { data } = await AddRecord(
        `/admin/payouts/requests/${id}`,
        { status: "accept" },
        userInfo?.accessToken
      );
      if (data.success) {
        toastMessage(data);
        dispatch({ type: reducerCases.SET_REFRESH, refresh: true });
      } else {
        toastMessage(data);
      }
    })(id);
  };

  const handlePayoutReject = (id) => {
    (async function (id) {
      const { data } = await AddRecord(
        `/admin/payouts/requests/${id}`,
        { status: "reject" },
        userInfo?.accessToken
      );
      if (data.success) {
        toastMessage(data);
        dispatch({ type: reducerCases.SET_REFRESH, refresh: true });
      } else {
        toastMessage(data);
      }
    })(id);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  return (
    <div className="px-4 h-full overflow-y-auto no-scrollbar space-y-3">
      <div className="bg-red-50 rounded-lg p-4">
        <h1 className="text-xl font-semibold pb-4">Payout Requests</h1>
        <div className="grid grid-cols-3 gap-2 pb-2">
          {/* CARD 1 */}

          {card &&
            card.map((item, index) => (
              <div className="h-[160px] border shadow-md text-gray-700 bg-white hover:shadow-xl transition-all ease-in-out duration-300 rounded-md flex justify-between items-center space-x-4 px-2 py-2 cursor-pointer">
                <div className="flex flex-col space-y-4 h-full w-[calc(100%-30%)]">
                  <div className="shadow-lg ring-1 ring-gray-100 px-2 rounded-lg w-[25%]">
                    {item.icon}
                  </div>
                  <div className=" h-full relative">
                    <div className="flex flex-col space-y-2 items-start absolute bottom-0">
                      <span className="text-md font-normal">{item?.name}</span>
                      <span className="text-lg font-bold">{item?.count}</span>
                    </div>
                  </div>
                </div>
                <div className="w-[30%] h-full relative flex justify-center">
                  <div className="border absolute border-gray-800 px-6 rounded-full bottom-2">
                    <span className="text-mg font-semibold text-gray-600">
                      20%
                    </span>
                  </div>
                </div>
              </div>
            ))}

          {/* END CARD  */}
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full text-sm text-left text-gray-500">
          <thead class="text-xs text-white uppercase bg-sw-100/[0.8]">
            <tr>
              <th scope="col" class="px-6 py-3">
                S.No
              </th>
              <th scope="col" class="px-6 py-3">
                Name
              </th>
              <th scope="col" class="px-6 py-3">
                Phone
              </th>
              <th scope="col" class="px-6 py-3">
                Amount
              </th>
              <th scope="col" class="px-6 py-3">
                createdAt
              </th>
              <th scope="col" class="px-6 py-3">
                Payment Status
              </th>
              <th scope="col" class="px-6 py-3">
                Status
              </th>
              <th scope="col" class="px-6 py-3">
                <div className="text-center">Action</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {withdrawal_requests &&
              withdrawal_requests?.map((item, index) => (
                <tr
                  class={`${
                    (index + 1) % 2 == 0 ? "bg-gray-100" : "bg-gray-50"
                  }`}
                  key={index + 1}
                >
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                  >
                    {index + 1}
                  </th>
                  <td class="px-6 py-4">
                    {item?.User?.fname} {item?.User?.lname}
                  </td>
                  <td class="px-6 py-4">{item?.User?.phone}</td>
                  <td class="px-6 py-4">Rs.{item?.amount}</td>
                  <td class="px-6 py-4">
                    <span>{calculateTime(item.createdAt)}</span>
                  </td>
                  <td class="px-6 py-4">
                    <span className="capitalize">{item.paymentStatus}</span>
                  </td>
                  <td class="px-6 py-4">
                    <span className="capitalize">{item.status}</span>
                  </td>

                  <td class="px-6 py-4">
                    <div className="text-center space-x-3">
                      {item?.status === "request" ? (
                        <>
                          <button
                            onClick={() => handlePayoutApproved(item.id)}
                            className="bg-yellow-500 hover:bg-yellow-400 py-1 px-4 rounded-lg cursor-pointer font-semibold text-gray-50"
                          >
                            Approved
                          </button>
                          <button
                            onClick={() => handlePayoutReject(item.id)}
                            className="bg-sw-100 hover:bg-sw-100/[0.6] py-1 px-4 rounded-lg cursor-pointer font-semibold text-gray-50"
                          >
                            Reject
                          </button>
                        </>
                      ) : (
                        <>
                          {item?.status === "accept" ? (
                            <button className="bg-green-500 py-1 px-4 rounded-lg cursor-pointer font-semibold text-gray-50">
                              Approved
                            </button>
                          ) : (
                            <button className="bg-sw-100 py-1 px-4 rounded-lg cursor-pointer font-semibold text-gray-50">
                              Reject
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <div>
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          limit={limit}
          setLimit={setLimit}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default Requests;
