import API from "../config/api";

export const LoginAuth = async (url, payload) => {
  try {
    const response = await API.post(url, payload);
    return response;
  } catch (error) {
    return { data: error?.response?.data };
  }
};

export const UploadFile = async (url, payload, token) => {
  try {
    const response = await API.post(url, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return { data: error?.response?.data };
  }
};
