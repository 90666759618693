import React, { useEffect, useState } from "react";
import { AiFillDelete, AiOutlineSearch } from "react-icons/ai";
import { FiEdit, FiPlusCircle } from "react-icons/fi";
import { BsFillEyeFill } from "react-icons/bs";
import styles from "./services.module.css";
import { useStateProvider } from "../../context/StateReducers";
import { reducerCases } from "../../context/constants";
import {
  deleteRecord,
  getRecord,
  getServices,
} from "../../services/services.api";
import Pagination from "../../components/form/Pagination";
import { Link } from "react-router-dom";
import { calculateTime } from "../../utils/dateUtils";
import { toastMessage } from "../../components/toast/toastMessage";
import CouponsModel from "./CouponsModel";
import CopyableField from "../../components/form/CopyableField";

const Coupons = () => {
  const [isToggle, setIsToggle] = useState(false);
  const [{ coupons, userInfo, refresh }, dispatch] = useStateProvider();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(null);
  const [totalPages, setTotalPages] = useState([]);
  const [editItem, setEditItem] = useState(null);

  useEffect(() => {
    !isToggle && setEditItem(null);
  }, [isToggle]);

  useEffect(() => {
    callApis();
  }, [page, limit, userInfo, refresh]);

  async function callApis() {
    if (userInfo) {
      let params = {};
      params.limit = limit;
      params.page = page;
      const { data } = await getRecord(
        "/coupons/list",
        params,
        userInfo?.accessToken
      );

      if (data.success) {
        dispatch({
          type: reducerCases.GET_COUPONS,
          coupons: data.data.rows,
        });
        setCurrentPage(data.data.currentPage);
        setTotalPages(
          Array.from({ length: data.data.totalPages }, (_, index) => index + 1)
        );
        dispatch({ type: reducerCases.SET_REFRESH, refresh: false });
      }
    }
  }

  const handleDelete = (id) => {
    (async function (id) {
      let params = {};
      const { data } = await deleteRecord(
        `/coupons/delete/${id}`,
        params,
        userInfo?.accessToken
      );

      if (data.success) {
        dispatch({ type: reducerCases.SET_REFRESH, refresh: true });
        toastMessage(data);
      }
    })(id);
  };

  const editHandleClick = (id) => {
    (async function (id) {
      const query = {};
      const { data } = await getServices(
        `/coupons/view/${id}`,
        query,
        userInfo?.accessToken
      );

      if (data.success) {
        setIsToggle(!isToggle);
        setEditItem(data?.data);
      } else {
        toastMessage(data);
      }
    })(id);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  return (
    <div className="px-4 h-full overflow-y-auto no-scrollbar">
      <h1 className="border-b py-2 font-medium text-lg text-gray-600">
        Coupons
      </h1>
      <div className="flex justify-between my-4">
        <div>
          <h1>Filter:</h1>
        </div>
        <div className="flex items-center space-x-2">
          <div>
            <input
              type="text"
              className="bg-gray-100 focus-within:outline-none py-2 px-2 rounded-lg"
              placeholder="Search..."
            />
          </div>
          <button
            onClick={() => setIsToggle(!isToggle)}
            className="flex items-center bg-sw-100 px-4 py-2 rounded-2xl space-x-2 text-white"
          >
            <FiPlusCircle />
            <span>Add</span>
          </button>
        </div>
      </div>

      {/* Table Wrapper with Horizontal Scroll */}
      <div className="w-full overflow-x-auto no-scrollbar">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-white uppercase bg-sw-100/[0.8]">
            <tr>
              <th scope="col" className="px-6 py-3">
                S.No
              </th>
              <th scope="col" className="px-6  py-3">
                Type
              </th>
              <th scope="col" className="px-6 py-3">
                Code
              </th>
              <th scope="col" className="px-6 py-3">
                description
              </th>

              <th scope="col" className="px-6 py-3">
                ExpiredOn
              </th>

              <th scope="col" className="px-6 py-3">
                Price
              </th>

              <th scope="col" className="px-6 py-3">
                Discount
              </th>
              <th scope="col" className="px-6 py-3">
                CreatedAt
              </th>
              <th scope="col" className="px-6 py-3">
                Status
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>

          <tbody>
            {coupons &&
              coupons?.map((item, index) => (
                <tr
                  class={`${
                    (index + 1) % 2 == 0 ? "bg-gray-100" : "bg-gray-50"
                  }`}
                  key={index + 1}
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                  >
                    {index + 1}
                  </th>
                  <td className="px-6 py-4">{item?.type}</td>
                  <td className="px-6 py-4">
                    <CopyableField code={item?.code} />
                  </td>
                  <td className="px-6 py-4">
                    <div className="line-clamp-2 justify-between capitalize w-[200px]">
                      {item.desciption}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    {calculateTime(item?.validUntil)}
                  </td>

                  <td className="px-6 py-4">
                    <p className="line-clamp-2 justify-between capitalize">
                      {item?.price}
                      {item?.bookingApplyRanges &&
                        "/" + item?.bookingApplyRanges}
                    </p>
                  </td>
                  <td className="px-6 py-4">
                    <p className="line-clamp-2 justify-between capitalize">
                      {item?.discount}
                    </p>
                  </td>
                  <td className="px-6 py-4 first-letter:uppercase">
                    {calculateTime(item?.createdAt)}
                  </td>
                  <td className="px-6 py-4 text-green-700 first-letter:uppercase">
                    {item.status}
                  </td>

                  <td className="px-6 py- space-x-4">
                    <div className="flex space-x-2">
                      <button
                        onClick={() => editHandleClick(item?.id)}
                        class="font-medium"
                      >
                        <FiEdit className="text-md" />
                      </button>
                      <Link to={`/coupons/${item?.id}`} className="font-medium">
                        <BsFillEyeFill className="text-md" />
                      </Link>
                      <button
                        onClick={() => handleDelete(item?.id)}
                        className="font-medium text-sw-100"
                      >
                        <AiFillDelete className="text-md" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <div>
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          limit={limit}
          setLimit={setLimit}
          handlePageChange={handlePageChange}
        />
      </div>

      {isToggle && (
        <CouponsModel
          isToggle={isToggle}
          setIsToggle={setIsToggle}
          editItem={editItem}
        />
      )}
    </div>
  );
};

export default Coupons;
