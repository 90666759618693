import React from "react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

const OrderView = () => {
  const navigate = useNavigate();
  return (
    <div className="px-2 py-2">
      <div className="flex items-center justify-start space-x-4 border-b-2 py-3">
        <span onClick={() => navigate(-1)}>
          <BiArrowBack className="text-lg cursor-pointer font-bold" />
        </span>
        <p className="text-xs font-medium">
          <span className="bg-red-600 px-2 py-1 text-white rounded-lg capitalize">
            Packages
          </span>
          {" / "}
          <span className="bg-red-600 px-2 py-1 text-white rounded-lg capitalize">
            shop
          </span>
        </p>
      </div>
    </div>
  );
};

export default OrderView;
