import { AiOutlineMinusCircle } from "react-icons/ai";
import { FaTimes } from "react-icons/fa";
const ImageViewer = ({ fileDataURL, handleImages }) => {
  return (
    <>
      {fileDataURL &&
        fileDataURL.map((item, index) => (
          <div
            key={index + 1}
            className="flex items-center w-full h-[220px] relative p-1"
          >
            <button
              type="button"
              onClick={() => handleImages(item)}
              className="absolute top-2 right-2 text-sw-100"
            >
              <FaTimes size={20} className="text-lightGreen" />
            </button>

            <img
              src={item}
              alt="upload file"
              className="w-full h-full rounded-lg shadow-md bg-white"
            />
          </div>
        ))}
    </>
  );
};

export const BrowseImages = ({ fileDataURL, handleImages }) => {
  return (
    <>
      {fileDataURL &&
        fileDataURL.map((item, index) => (
          <div
            key={index + 1}
            className="flex items-center w-full h-full relative p-1"
          >
            <button
              type="button"
              onClick={() => handleImages(item)}
              className="absolute top-2 right-2 text-sw-100"
            >
              <FaTimes size={20} className="text-lightGreen" />
            </button>

            <img
              src={item}
              alt="upload file"
              className="w-full h-full rounded-lg shadow-md bg-white"
            />
          </div>
        ))}
    </>
  );
};

export const FullScreen = ({ isFullScreen, setIsfullScreen, imageURL }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center backdrop-blur-sm z-20 w-full border border-slate-400">
      <button onClick={() => setIsfullScreen(!isFullScreen)}>
        <span>
          <AiOutlineMinusCircle className="absolute top-5 right-5 text-2xl text-sw-100 hover:text-3xl" />
        </span>
      </button>
      <div className={`w-[100%] h-full bg-gray-100/[0.6]`}>
        <img src={imageURL} alt="Full Screen" className="w-full h-full" />
      </div>
    </div>
  );
};

export default ImageViewer;
