import React, { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { MdCategory, MdVerified } from "react-icons/md";
import { GiDuration } from "react-icons/gi";
import { IoIosMan, IoIosWoman } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { getRecord } from "../../services/services.api";
import { useStateProvider } from "../../context/StateReducers";
import { reducerCases } from "../../context/constants";
import { toastMessage } from "../../components/toast/toastMessage";
import { calculateTime } from "../../utils/dateUtils";
import { BsCurrencyRupee } from "react-icons/bs";
import styles from "./packages.module.css";

const PackageView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [{ userInfo, packageView }, dispatch] = useStateProvider();
  const [coverImage, setCoverImage] = useState(null);

  useEffect(() => {
    if (userInfo) {
      callApi();
    }
  }, [userInfo]);

  const callApi = async () => {
    const query = {};
    const { data } = await getRecord(
      `/admin/packages/view/${id}/admin`,
      query,
      userInfo?.accessToken
    );

    if (data.success) {
      dispatch({ type: reducerCases.VIEW_PACKAGE, packageView: data?.data });
    } else {
      toastMessage(data);
    }

    console.log(packageView);
  };

  return (
    <div className="px-2 py-2 ">
      <div className="flex items-center justify-start space-x-4 border-b-2 py-3">
        <span onClick={() => navigate(-1)}>
          <BiArrowBack className="text-lg cursor-pointer font-bold" />
        </span>
        <p className="text-xs font-medium">
          <span className="bg-red-600 px-2 py-1 text-white rounded-lg capitalize">
            Packages
          </span>
          {" / "}
          <span className="bg-red-600 px-2 py-1 text-white rounded-lg capitalize">
            {packageView?.name}
          </span>
        </p>
      </div>

      <div className="my-2">
        <div className="flex flex-wrap justify-between gap-4">
          <div className="w-full flex-shrink md:flex-1">
            <div className="h-80 rounded-lg shadow-md ring-1 ring-gray-100">
              <img
                className="h-full w-full rounded-lg"
                src={coverImage ? coverImage : packageView?.images[0]}
                alt="icon"
              />
            </div>

            <div
              className={`px-2 py-2 overflow-x-auto ${styles.ImageScrollerContainer} `}
            >
              <div className="flex space-x-2 cursor-pointer">
                {packageView &&
                  packageView?.images.map((url) => (
                    <div className="h-16 w-fit flex-shrink-0">
                      <img
                        onClick={() => setCoverImage(url)}
                        className="h-full w-full rounded-lg"
                        src={url}
                        alt="icon"
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className="w-full flex-grow md:flex-1 pb-5">
            <div className="flex items-center space-x-2">
              <span>
                {packageView?.status === "active" && (
                  <MdVerified className="text-green-600 font-bold text-lg" />
                )}
              </span>
              <p className="text-lg font-semibold capitalize">
                {packageView?.name}
              </p>
              <p className="flex items-center space-x-2 text-lg">
                <span className=" text-sw-100">
                  {packageView?.packageTypes === "man" ? (
                    <IoIosMan cln />
                  ) : (
                    <IoIosWoman />
                  )}
                </span>
                <span className="text-sm font-semibold capitalize">
                  {"< "}
                  {packageView?.packageTypes}
                  {" >"}
                </span>
              </p>
            </div>

            <div className="flex gap-4 mt-2">
              <div>
                <span className="text-sm font-medium">Created At : </span>
                <span className="text-sm font-normal">
                  {calculateTime(packageView?.createdAt)}
                </span>
              </div>
              <div>
                <span className="text-sm font-medium">Expires On : </span>
                <span
                  className={`text-sm font-normal ${
                    packageView?.expired
                      ? "bg-red-600 text-white px-2 rounded-lg"
                      : "bg-green-600 text-white px-2 rounded-lg"
                  }`}
                >
                  {calculateTime(packageView?.expiredOn)}
                </span>
              </div>
            </div>

            <div className="flex gap-2 mb-2">
              <div>
                <span className="text-sm font-medium">Last Updated : </span>
                <span className="text-sm font-normal">
                  {calculateTime(packageView?.updatedAt)}
                </span>
              </div>
            </div>

            <div className="flex gap-2 mb-2">
              <div>
                <span className="text-sm font-medium">Applicable Type : </span>
                <span className="text-sm font-normal capitalize">
                  {packageView?.type}
                </span>
              </div>
            </div>

            <div className="flex mt-1 items-center space-x-2">
              <span>
                <MdCategory className="text-lg" />
              </span>
              <span className="text-sm font-medium">Services Included : </span>
            </div>

            <div className="flex mt-1 mb-3 gap-2">
              {packageView?.services.map((service, id) => (
                <span
                  key={id + 1}
                  className="text-xs capitalize px-2 bg-red-600 text-white rounded-lg"
                >
                  {service?.name}
                </span>
              ))}
            </div>

            <div className=" w-[92%]">
              <p className="text-sm font-normal w-fit text-gray-600 capitalize px-1 rounded-md bg-slate-200">
                {packageView?.description}
              </p>
            </div>

            <div className="flex items-center mt-2">
              <span className="text-sm font-medium ">Actual Price : </span>
              <span className="flex items-center pl-1 text-sm font-normal capitalize">
                <BsCurrencyRupee />
                {Number(packageView?.price)}
              </span>
            </div>

            <div className="flex items-center mt-2">
              <span className="text-sm font-medium ">Offer Price : </span>
              <span className="flex items-center pl-1 text-sm font-normal capitalize">
                <BsCurrencyRupee />
                {Number(packageView?.offerPrice)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageView;
