import React, { useState, useEffect } from "react";
import { AiOutlineCloudUpload, AiOutlinePlusSquare } from "react-icons/ai";
import { FaTimes } from "react-icons/fa";
import ImageViewer from "../form/ImageViewer";
import Styles from "../../components/form/scrollbar.module.css";
import { UploadFile } from "../../services/auth.api";
import { useStateProvider } from "../../context/StateReducers";
import {
  AddRecord,
  UpdateRecord,
  getCategories,
} from "../../services/services.api";
import { toastMessage } from "../toast/toastMessage";
import { useForm } from "react-hook-form";
import { reducerCases } from "../../context/constants";

const ServicesModel = ({ isToggle, setIsToggle, editData }) => {
  const [fileDataURL, setFileDataURL] = useState(null);
  const [{ userInfo }, dispatch] = useStateProvider();
  const [categories, setCategories] = useState(null);
  const [selectType, setSelectType] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (editData) {
      setFileDataURL(editData?.urls);
      setSelectType(editData?.type);
    }
  }, []);

  useEffect(() => {
    callApis();
  }, [selectType]);

  async function callApis() {
    if (selectType) {
      let params = {};
      params.limit = 50;
      params.page = 1;
      params.shopType = selectType;
      const { data } = await getCategories(
        "/admin/services/category/list/admin",
        params,
        userInfo?.accessToken
      );

      console.log({ data });

      if (data.success) {
        setCategories(data?.data?.rows);
      }
    }
  }

  const UploadFiles = (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    (async function () {
      const { data } = await UploadFile(
        "/admin/files/bucket",
        formData,
        userInfo?.accessToken
      );
      if (data.success) {
        if (fileDataURL) {
          const updateFiles = [...fileDataURL, data.data.url];
          setFileDataURL(updateFiles);
        } else {
          const updateFiles = [data.data.url];
          setFileDataURL(updateFiles);
        }
      }
    })();
  };

  const createData = (payload) => {
    (async function (payload) {
      if (editData) {
        payload.images = fileDataURL && fileDataURL;
        const { data } = await UpdateRecord(
          `/admin/services/update/${editData?.id}`,
          payload,
          userInfo?.accessToken
        );

        if (data.success) {
          toastMessage(data);
          dispatch({ type: reducerCases.SET_REFRESH, refresh: true });
          setIsToggle(false);
        } else {
          toastMessage(data);
        }
      } else {
        if (!fileDataURL) {
          toastMessage({ message: "File is required" });
        } else {
          payload.images = fileDataURL && fileDataURL;
          const { data } = await AddRecord(
            "/admin/services/add",
            payload,
            userInfo?.accessToken
          );

          if (data.success) {
            toastMessage(data);
            dispatch({ type: reducerCases.SET_REFRESH, refresh: true });
            setIsToggle(false);
          } else {
            toastMessage(data);
          }
        }
      }
    })(payload);
  };

  const handleImages = (item) => {
    const updateFiles =
      fileDataURL && fileDataURL.filter((file) => file !== item);
    updateFiles.length !== 0
      ? setFileDataURL(updateFiles)
      : setFileDataURL(null);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center backdrop-blur-md z-10 w-full border border-slate-400">
      <div className={`w-[100%] h-full bg-gray-100/[0.6]`}>
        <div className="w-full h-full flex justify-center items-center">
          <div className="w-[96%] sm:w-[74%] md:w-[64%] lg:w-[50%] h-[90vh] shadow-md border text-gray-900 bg-red-400 rounded-lg px-2 py-2 relative">
            <FaTimes
              onClick={() => setIsToggle(!isToggle)}
              className="absolute right-5 top-5 text-2xl cursor-pointer text-sw-100"
            />
            <form
              onSubmit={handleSubmit((data) => createData(data))}
              className="h-[100%] w-full "
            >
              <div className="h-[calc(10%)] w-full">
                <h1 className="text-2xl font-semibold text-center text-black uppercase border-b-2 py-2 mb-4">
                  {editData ? "Edit Services" : "Services"}
                </h1>
              </div>

              <div
                className={`h-[calc(100%-20%)] w-[100%] overflow-y-auto ${Styles.editorContainerModel}`}
              >
                <div className="flex w-full gap-2 mb-2">
                  <div className="flex flex-col w-1/2 space-y-1">
                    <span className="text-md font-semibold capitalize">
                      Type
                    </span>
                    <select
                      {...register("type", {
                        value: editData && editData?.type,
                        required: true,
                      })}
                      value={selectType}
                      onChange={(e) => setSelectType(e.target.value)}
                      className="p-1 rounded-md shadow-md capitalize focus:outline-none hover:cursor-pointer"
                    >
                      <option className="text-sm" value="">
                        Select Type
                      </option>
                      <option className="text-sm" value="salons">
                        Salons
                      </option>
                      <option className="text-sm" value="parlours">
                        Parlours
                      </option>
                      <option className="text-sm" value="spas">
                        spa
                      </option>
                    </select>
                    {errors.type && (
                      <span className="text-sm text-sw-100">
                        Type is required
                      </span>
                    )}
                  </div>

                  <div className="flex flex-col w-1/2 space-y-1">
                    <span className="text-md font-semibold capitalize">
                      Categories
                    </span>
                    <select
                      {...register("catId", {
                        required: true,
                      })}
                      className="p-1 rounded-md shadow-md capitalize focus:outline-none hover:cursor-pointer"
                    >
                      {editData ? (
                        <>
                          <option
                            className="text-sm"
                            value={editData?.category?.id}
                          >
                            {editData?.category?.name}
                          </option>
                          {categories &&
                            categories
                              .filter(
                                (item) =>
                                  parseInt(item?.id) !==
                                  parseInt(editData?.catId)
                              )
                              .map((category) => (
                                <option className="text-sm" value={category.id}>
                                  {category?.name}
                                </option>
                              ))}
                        </>
                      ) : (
                        <>
                          <option className="text-sm" value="">
                            Select Category
                          </option>
                          {categories &&
                            categories.map((category) => (
                              <option className="text-sm" value={category.id}>
                                {category?.name}
                              </option>
                            ))}
                        </>
                      )}
                    </select>
                    {errors.catId && (
                      <span className="text-sm text-sw-100">
                        Category is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="flex w-full gap-2 mb-2">
                  <div className="flex flex-col w-1/2 space-y-1">
                    <span className="text-md font-semibold capitalize">
                      Service Type
                    </span>
                    <select
                      {...register("serviceType", {
                        required: true,
                      })}
                      className="p-1 rounded-md shadow-md capitalize focus:outline-none hover:cursor-pointer"
                    >
                      {/* editData && editData?.serviceType, */}
                      <option className="text-sm" value="">
                        Select Service Type
                      </option>
                      <option
                        value="M"
                        className="text-sm"
                        selected={editData?.serviceType === "M" && true}
                      >
                        Male
                      </option>
                      <option
                        value="F"
                        className="text-sm"
                        selected={editData?.serviceType === "F" && true}
                      >
                        Female
                      </option>
                    </select>
                    {errors.serviceType && (
                      <span className="text-sm text-sw-100">
                        serviceType is required
                      </span>
                    )}
                  </div>

                  <div className="flex flex-col w-1/2 space-y-1">
                    <span className="text-md font-semibold capitalize">
                      Name
                    </span>
                    <input
                      {...register("name", {
                        value: editData && editData?.name,
                        required: true,
                      })}
                      className="p-1 rounded-md shadow-md capitalize focus:outline-none"
                      type="text"
                      placeholder="Enter name"
                    />
                    {errors.name && (
                      <span className="text-sm text-sw-100">
                        Name is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="flex w-full gap-2 mb-2">
                  <div className="flex flex-col w-1/2 space-y-1">
                    <span className="text-md font-semibold capitalize">
                      Duration
                    </span>
                    <input
                      {...register("duration", {
                        value: editData && editData?.duration,
                        required: true,
                      })}
                      className="p-1 rounded-md shadow-md capitalize focus:outline-none"
                      type="text"
                      placeholder="Enter duration"
                    />
                    {errors.duration && (
                      <span className="text-sm text-sw-100">
                        Duration is required
                      </span>
                    )}
                  </div>

                  <div className="flex flex-col w-1/2 space-y-1">
                    <span className="text-md font-semibold capitalize">
                      Price
                    </span>
                    <input
                      {...register("price", {
                        value: editData && editData?.price,
                        required: true,
                      })}
                      className="p-1 rounded-md shadow-md capitalize focus:outline-none"
                      type="text"
                      placeholder="Enter price"
                    />
                    {errors.price && (
                      <span className="text-sm text-sw-100">
                        Price is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="flex flex-col space-y-1 mb-2">
                  <span className="text-md font-semibold capitalize">
                    Desciption
                  </span>
                  <textarea
                    {...register("description", {
                      value: editData && editData?.description,
                      required: true,
                    })}
                    className="p-2 rounded-md shadow-md capitalize focus:outline-none"
                    cols="30"
                    rows="10"
                    placeholder="Write...."
                  ></textarea>
                  {errors.description && (
                    <span className="text-sm text-sw-100">
                      Description is required
                    </span>
                  )}
                </div>

                <div className="flex flex-col mb-2 w-full">
                  <div className="relative w-full">
                    {fileDataURL && (
                      <div
                        // className={`flex md:grid-cols-${
                        //   fileDataURL.length === 1 ? 1 : 2
                        // }`}
                        className="grid grid-cols-1 md:grid-cols-2"
                      >
                        <ImageViewer
                          fileDataURL={fileDataURL}
                          handleImages={handleImages}
                        />
                      </div>
                    )}

                    {fileDataURL && (
                      <div className="border absolute top-3 left-3 bg-gray-100 py-1 rounded-lg shadow-md">
                        <label
                          htmlFor="dropzone-file"
                          className="flex items-center pr-8 pl-2 space-x-2 cursor-pointer"
                        >
                          <span>
                            <AiOutlinePlusSquare className="text-lg text-sw-100" />
                          </span>
                          <span>Add Photo</span>
                          <input
                            onChange={(e) => UploadFiles(e)}
                            accept="image/*"
                            id="dropzone-file"
                            type="file"
                            className="hidden"
                          />
                        </label>
                      </div>
                    )}
                  </div>

                  {!fileDataURL && (
                    <div className="border w-full flex justify-between items-center shadow-md px-4 rounded-lg cursor-pointer">
                      <div>
                        <span className="text-lg font-semibold text-gray-800">
                          Upload File
                        </span>
                      </div>
                      <div>
                        <label
                          for="dropzone-file"
                          className="flex flex-col justify-center items-center w-[80px] mx-2 my-2 rounded-lg cursor-pointer"
                        >
                          <div className="flex flex-col justify-center items-center rounded-full p-1 hover:bg-gray-200/[0.6]">
                            <AiOutlineCloudUpload className="text-4xl text-sw-100" />
                          </div>
                          <input
                            onChange={(e) => UploadFiles(e)}
                            accept="image/*"
                            id="dropzone-file"
                            type="file"
                            className="hidden"
                          />
                        </label>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="flex justify-end items-center py-4 border-t h-[calc(10%)]">
                <div className="flex justify-between items-center space-x-2">
                  <button
                    onClick={() => setIsToggle(!isToggle)}
                    className="bg-gray-200 text-gray-800 font-semibold w-fit py-1 px-2 rounded-lg text-md"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="bg-green-800 text-white font-semibold w-fit py-1 px-2 rounded-lg text-md"
                  >
                    {editData ? "Save Changes" : "Save"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesModel;
