import React, { useEffect, useState } from "react";
import { BsPencilSquare } from "react-icons/bs";
import { RiBankCard2Fill } from "react-icons/ri";
import { IoIosArrowForward } from "react-icons/io";
import { MdWorkHistory } from "react-icons/md";
import { BiSolidCoupon } from "react-icons/bi";
import { FaUsers, FaAward } from "react-icons/fa";
import { FiScissors } from "react-icons/fi";
import { RiCalendarCheckFill } from "react-icons/ri";
import { MdStore } from "react-icons/md";
import { BarChartBox, GenderPiChartBox } from "../../components/charts/Chart";
import { getServices } from "../../services/services.api";
import { useStateProvider } from "../../context/StateReducers";
import { reducerCases } from "../../context/constants";
import { toastMessage } from "../../components/toast/toastMessage";
import { getDayStr, getMonthStr, MOMENT } from "../../utils/dateUtils";

const Dashboard = () => {
  const [items, setItems] = useState(null);

  const [{ userInfo, dashboard }, dispatch] = useStateProvider();

  useEffect(() => {
    if (userInfo) {
      callApis();
    }
  }, [userInfo]);

  async function callApis() {
    const query = {};
    const { data } = await getServices(
      `/admin/dashboard`,
      query,
      userInfo?.accessToken
    );

    if (data.success) {
      dispatch({ type: reducerCases.DASHBOARD, dashboard: data?.data });
      setItems([
        {
          name: "Clients",
          count: dashboard?.customers,
          icon: <FaUsers className="text-2xl text-sw-100" />,
        },
        {
          name: "Appointments",
          count: dashboard?.appointments,
          icon: <RiCalendarCheckFill className="text-2xl text-sw-100" />,
        },
        {
          name: "Services",
          count: dashboard?.services,
          icon: <FiScissors className="text-2xl text-sw-100" />,
        },
        {
          name: "Shops",
          count: dashboard?.shops,
          icon: <MdStore className="text-2xl text-sw-100" />,
        },
        {
          name: "Coupons",
          count: dashboard?.coupons,
          icon: <BiSolidCoupon className="text-2xl text-sw-100" />,
        },
        {
          name: "Awards",
          count: dashboard?.awards,
          icon: <FaAward className="text-2xl text-sw-100" />,
        },
      ]);
    } else {
      console.log({ dashboard: data });
      toastMessage(data);
    }
  }

  return (
    <div className="flex flex-col md:flex-row px-4 h-full overflow-y-auto no-scrollbar">
      <div className="flex-1 pr-2">
        <div className="bg-slate-50 shadow-md rounded-md p-3">
          <h1 className="text-xl font-semibold pb-2 mb-4 border-b">
            Dashboard
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-2 pb-4">
            <div className="flex justify-center items-center bg-sw-100/[0.2] h-40 shadow-md rounded-lg">
              <div className="flex flex-col items-center">
                <FaUsers size={42} className="text-sw-100" />
                <span className="text-2xl md:text-2xl font-medium">
                  {dashboard?.customers}
                </span>
                <span className="text-sm font-medium text-slate-500">
                  Total Users
                </span>
              </div>
            </div>

            <div className="flex justify-center items-center bg-sw-100/[0.2] h-40 shadow-md rounded-lg relative">
              <div className="flex flex-col items-center">
                <MdWorkHistory size={42} className="text-sw-100" />
                <span className="text-2xl md:text-2xl font-medium">
                  {dashboard?.appointments}
                </span>
                <span className="text-sm font-medium text-slate-500">
                  Total Bookings
                </span>
              </div>
            </div>

            <div className="flex justify-center items-center bg-sw-100/[0.2] h-40 shadow-md rounded-lg">
              <div className="flex flex-col items-center">
                <RiBankCard2Fill size={42} className="text-sw-100" />
                <span className="text-2xl md:text-2xl font-medium">
                  {dashboard?.appFee?.totalRevenue}
                </span>
                <span className="text-sm font-medium text-slate-500">
                  Total Revenue
                </span>
              </div>
            </div>

            <div className="flex justify-center items-center bg-sw-100/[0.2] h-40 shadow-md rounded-lg">
              <div className="flex flex-col items-center">
                <BsPencilSquare size={42} className="text-sw-100" />
                <span className="text-2xl md:text-2xl font-medium">
                  {dashboard?.services}
                </span>
                <span className="text-sm font-medium text-slate-500">
                  Total Services
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 my-4 shadow-md rounded-lg">
          <div>
            <div>
              <h1 className="text-lg font-medium px-4">Bookings</h1>
            </div>
            <BarChartBox orderHistory={dashboard?.orderHistory} />
          </div>
          <div>
            <div>
              <h1 className="text-lg font-medium px-4">Male/Female</h1>
            </div>
            <GenderPiChartBox
              male={dashboard?.male}
              female={dashboard?.female}
            />
          </div>
        </div>

        <div className="flex">
          <div className="flex-1 mb-6">
            <h1 className="py-4 font-medium border-b text-lg text-slate-500">
              Recent Bookings
            </h1>
            <ul className="mt-4 space-y-4">
              {dashboard?.recentTenOrders &&
                dashboard?.recentTenOrders.map((item, idx) => (
                  <li
                    key={idx}
                    className="border border-gray-300 shadow-md rounded-lg overflow-hidden"
                  >
                    <div className="flex items-center bg-[#4DC591]">
                      <div className="h-full w-48">
                        <div className="flex flex-col items-center">
                          <span className="text-white font-medium text-7xl">
                            {getDayStr(item?.bookingDateTime)}
                          </span>
                          <span className="font-medium text-white">
                            {getMonthStr(item?.bookingDateTime)}
                          </span>
                        </div>
                      </div>
                      <div className="bg-slate-50 py-1 px-4 w-full h-full">
                        <div className="flex justify-between pb-2">
                          <div>
                            <p className="space-x-2">
                              <span className="text-black font-medium text-sm">
                                Booked By
                              </span>
                              <span className="text-slate-500 text-sm font-medium">
                                {item?.User?.firstName} {item?.User?.lastName}
                              </span>
                            </p>
                          </div>
                          <div className="flex items-center">
                            <p className="space-x-2 border px-4 rounded-3xl cursor-pointer hover:bg-slate-200 transition-all ease-in-out duration-300">
                              <span className="text-sm font-bold">
                                Rs. {item?.totalPrice}
                              </span>
                            </p>
                          </div>
                        </div>

                        <h1 className="text-base text-black font-medium font-sans">
                          Services
                        </h1>
                        <div className="ml-8 py-2">
                          <ul className="list-disc">
                            <li>Service 1</li>
                            <li>Service 1</li>
                          </ul>
                        </div>

                        <div className="flex justify-between items-center pb-2">
                          <p className="font-medium font-sans space-x-3">
                            <span className="text-black font-medium text-sm">
                              Slot Time
                            </span>
                            <span className="text-slate-500 text-sm capitalize">
                              {MOMENT(item?.bookingDateTime)}
                            </span>
                          </p>
                          <p className="text-white font-bold uppercase text-sm bg-slate-500 px-4 rounded-2xl py-1">
                            {item?.status}
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
          <div></div>
        </div>
      </div>
      <div className="w-full mt-2 md:mt-0 md:w-[23%] h-24 px-2 rounded-lg">
        <h1 className="py-2 border-b font-medium text-lg">Active Artists</h1>
        <div className="mt-4">
          <ul>
            {Array.from({ length: 6 }, (_, i) => (
              <li>
                <div className="flex items-center space-x-2">
                  <div className="h-10 w-10 rounded-full overflow-hidden cursor-pointer border-2 border-green-400">
                    <img
                      src="/assets/sw-wall.png"
                      className="w-full h-full rounded-full"
                      alt="profile"
                    />
                  </div>
                  <div className="flex-1 flex justify-between items-center">
                    <div>
                      <h1 className="text-base font-medium">Vivek Kumar</h1>
                      <span className="text-xs text-slate-400">Header CEO</span>
                    </div>
                    <div className="cursor-pointer">
                      <IoIosArrowForward />
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
