import React, { useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { BsFillEyeFill } from "react-icons/bs";
import { FiPlusCircle } from "react-icons/fi";
import { useStateProvider } from "../../context/StateReducers";
import { reducerCases } from "../../context/constants";
import {
  deleteCategories,
  getCategories,
  getServices,
} from "../../services/services.api";
import Pagination from "../../components/form/Pagination";
import CategoryModel from "./CategoryModel";
import { Link } from "react-router-dom";
import { calculateTime } from "../../utils/dateUtils";
import { toastMessage } from "../../components/toast/toastMessage";

const Category = () => {
  const [isToggle, setIsToggle] = useState(false);
  const [{ categories, userInfo, refresh }, dispatch] = useStateProvider();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(null);
  const [totalPages, setTotalPages] = useState([]);
  const [category, setCategory] = useState(null);
  const [editData, setEditData] = useState(null);

  useEffect(() => {
    !isToggle && setCategory(null);
  }, [isToggle]);

  useEffect(() => {
    callApis();
  }, [page, limit, userInfo, refresh]);

  async function callApis() {
    if (userInfo) {
      let params = {};
      params.limit = limit;
      params.page = page;
      const { data } = await getCategories(
        "/admin/services/category/list/admin",
        params,
        userInfo?.accessToken
      );

      if (data.success) {
        dispatch({
          type: reducerCases.GET_CATEGORIES,
          categories: data.data.rows,
        });
        setCurrentPage(data.data.currentPage);
        setTotalPages(
          Array.from({ length: data.data.totalPages }, (_, index) => index + 1)
        );
        dispatch({ type: reducerCases.SET_REFRESH, refresh: false });
      }
    }
  }

  const handleDelete = (category) => {
    (async function (category) {
      let params = {};
      const { data } = await deleteCategories(
        `/admin/services/category/delete/${category}`,
        params,
        userInfo?.accessToken
      );

      if (data.success) {
        dispatch({ type: reducerCases.SET_REFRESH, refresh: true });
        toastMessage(data);
      }
    })(category);
  };

  const editHandleClick = (categoryId) => {
    (async function (categoryId) {
      const query = {};
      const { data } = await getServices(
        `/admin/services/category/view/${categoryId}/admin`,
        query,
        userInfo?.accessToken
      );

      if (data.success) {
        setIsToggle(!isToggle);
        setCategory(data?.data);
      } else {
        toastMessage(data);
      }
    })(categoryId);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  return (
    <div className="px-4 h-full overflow-y-auto no-scrollbar">
      <h1 className="border-b py-2 font-medium text-lg text-gray-600">
        Category
      </h1>
      <div className="flex justify-between my-4">
        <div>
          <h1>Filter:</h1>
        </div>
        <div className="flex items-center space-x-2">
          <div>
            <input
              type="text"
              className="bg-gray-100 focus-within:outline-none py-2 px-2 rounded-lg"
              placeholder="Search..."
            />
          </div>
          <button
            onClick={() => setIsToggle(!isToggle)}
            className="flex items-center bg-sw-100 px-4 py-2 rounded-2xl space-x-2 text-white"
          >
            <FiPlusCircle />
            <span>Add</span>
          </button>
        </div>
      </div>

      {/* Table Wrapper with Horizontal Scroll */}
      <div className="w-full overflow-x-auto no-scrollbar">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-white uppercase bg-sw-100/[0.8]">
            <tr>
              <th scope="col" className="px-6 py-3">
                S.No
              </th>
              <th scope="col" className="px-6  py-3">
                Type
              </th>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                description
              </th>
              <th scope="col" className="px-6 py-3">
                CreatedAt
              </th>
              <th scope="col" className="px-6 py-3">
                Status
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>

          <tbody>
            {categories &&
              categories.map((item, idx) => (
                <tr
                  key={idx}
                  className={`${idx % 2 === 0 ? "bg-gray-100" : "bg-gray-50"}`}
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                  >
                    {idx + 1}
                  </th>
                  <td className="px-6 py-4">{item?.type}</td>
                  <td className="px-6 py-4">{item?.name}</td>
                  <td className="px-6 py-4">
                    <p className="line-clamp-2 justify-between capitalize">
                      {item?.description}
                    </p>
                  </td>
                  <td className="px-6 py-4">
                    {calculateTime(item?.createdAt)}
                  </td>
                  <td className="px-6 py-4 text-green-700 first-letter:uppercase">
                    {item?.status}
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center space-x-2">
                      <button
                        onClick={() => editHandleClick(item?.id)}
                        className="text-blue-500 hover:underline"
                      >
                        <FiEdit className="text-md" />
                      </button>
                      <Link
                        to={`/category/${item?.id}`}
                        className="text-green-500 hover:underline"
                      >
                        <BsFillEyeFill className="text-md" />
                      </Link>

                      <button
                        onClick={() => handleDelete(item?.id)}
                        className="text-red-500 hover:underline"
                      >
                        <AiFillDelete className="text-md" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <div>
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          limit={limit}
          setLimit={setLimit}
          handlePageChange={handlePageChange}
        />
      </div>

      {isToggle && (
        <CategoryModel
          isToggle={isToggle}
          setIsToggle={setIsToggle}
          editData={editData}
        />
      )}
    </div>
  );
};

export default Category;
