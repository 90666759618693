import React, { useEffect, useState } from "react";
import { RiBubbleChartFill } from "react-icons/ri";
import styles from "../services/services.module.css";
import { BiLogoBlogger } from "react-icons/bi";
import { AiFillDelete, AiOutlineSearch } from "react-icons/ai";
import BlogModel from "./BlogModel";
import { useStateProvider } from "../../context/StateReducers";
import { UpdateRecord, getRecord } from "../../services/services.api";
import { reducerCases } from "../../context/constants";
import { BsFillEyeFill } from "react-icons/bs";
import { FiEdit, FiPlusCircle } from "react-icons/fi";
import { Link } from "react-router-dom";
import Pagination from "../../components/form/Pagination";
import moment from "moment/moment";
import { toastMessage } from "../../components/toast/toastMessage";

const BlogInfoCard = ({ title, amount, percent, logo }) => {
  return (
    <div className="h-[180px] shadow-md text-gray-700 ring-1 ring-gray-200 hover:shadow-xl transition-all ease-in-out duration-300 rounded-md flex justify-between items-center p-2 cursor-pointer">
      <div className="flex flex-col space-y-4 h-full w-[calc(100%-30%)]">
        <div className="shadow-lg ring-1 flex flex-col aspect-square justify-center items-center ring-gray-100 px-2 rounded-lg w-[25%]">
          {logo}
        </div>
        <div className=" h-full relative">
          <div className="flex flex-col space-y-2 items-start absolute bottom-0">
            <span className="text-md font-normal">{title}</span>
            <span className="text-lg font-bold">{amount}</span>
          </div>
        </div>
      </div>
      <div className="w-[30%] h-full relative flex justify-center">
        <div className="border absolute border-gray-800 px-6 rounded-full bottom-2">
          <span className="text-mg font-semibold text-gray-600">
            {percent}%
          </span>
        </div>
      </div>
    </div>
  );
};

const Blogs = () => {
  const [isToggle, setIsToggle] = useState(false);
  const [{ blogs, userInfo, refresh }, dispatch] = useStateProvider();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(null);
  const [totalPages, setTotalPages] = useState([]);
  const [activeBlogs, setActiveBlogs] = useState(null);
  const [inActiveBlogs, setInActiveBlogs] = useState(null);
  const [latestBlog, setLatestBlog] = useState(null);
  const [editData, setEditData] = useState(null);

  useEffect(() => {
    callApis();
  }, [page, limit, userInfo, refresh]);

  async function callApis() {
    if (userInfo) {
      let params = {};
      params.limit = limit;
      params.page = page;
      const { data } = await getRecord(
        "/admin/blogs/list/admin",
        params,
        userInfo?.accessToken
      );

      if (data.success) {
        dispatch({
          type: reducerCases.GET_BLOGS,
          blogs: data.data.rows,
        });
        setLatestBlog(data.data.rows[0]);
        setCurrentPage(data.data.currentPage);
        console.log("data.data.totalPages", data.data.totalPages);
        setTotalPages(
          Array.from({ length: data.data.totalPages }, (_, index) => index + 1)
        );
        setActiveBlogs(data.data.activeBlogs);
        setInActiveBlogs(data.data.inActiveBlogs);
        dispatch({ type: reducerCases.SET_REFRESH, refresh: false });
      }
    }
  }

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleDelete = (category) => {
    // (async function (category) {
    //   let params = {};
    //   const { data } = await deleteCategories(
    //     `/admin/services/category/delete/${category}`,
    //     params,
    //     userInfo?.accessToken
    //   );
    //   if (data.success) {
    //     dispatch({ type: reducerCases.SET_REFRESH, refresh: true });
    //     toastMessage(data);
    //   }
    // })(category);
  };

  const handleUpdateStatus = (e, id) => {
    console.log(e.target.checked);
    let payload = {};
    if (e.target.checked) {
      payload.status = "active";
    } else {
      payload.status = "inactive";
    }
    (async function (id) {
      const { data } = await UpdateRecord(
        `/admin/blogs/update/${id}/status`,
        payload,
        userInfo?.accessToken
      );
      if (data.success) {
        dispatch({ type: reducerCases.SET_REFRESH, refresh: true });
        toastMessage(data);
      }
    })(id);
  };

  const editHandleClick = (id) => {
    (async function (id) {
      const query = {};
      const { data } = await getRecord(
        `/admin/blogs/view/${id}`,
        query,
        userInfo?.accessToken
      );
      if (data.success) {
        setIsToggle(!isToggle);
        setEditData(data?.data);
      } else {
        toastMessage(data);
      }
    })(id);
  };

  return (
    <div className="px-4 h-full overflow-y-auto no-scrollbar">
      <h1 className="border-b py-2 mb-4 font-medium text-lg text-gray-600">
        Services
      </h1>
      <div className="grid grid-cols-3 gap-4">
        <BlogInfoCard
          amount={50}
          title={"Active Blogs"}
          percent={20}
          logo={<BiLogoBlogger className="text-2xl text-sw-100" />}
        />
        <BlogInfoCard
          amount={50}
          title={"Total Blogs"}
          percent={20}
          logo={<BiLogoBlogger className="text-2xl text-sw-100" />}
        />

        <div className="h-[180px] shadow-md text-gray-700 ring-1 ring-gray-200 hover:shadow-xl transition-all ease-in-out duration-300 rounded-md flex justify-between items-center space-x-4 cursor-pointer">
          <div className="h-[180px] w-full bg-cover bg-center rounded-md custom-bg-image">
            <div className="flex flex-col justify-between h-full bg-black/[0.4] rounded-lg px-4 py-4">
              <div>
                <span className="text-white font-semibold">Latest Blog</span>
              </div>
              <div>
                <h1 className="text-[14px] text-gray-50">
                  {latestBlog && latestBlog?.title}
                </h1>
                <span className="text-gray-300 text-sm">
                  {latestBlog && moment(latestBlog?.createdAt).format("ll")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-between my-4">
        <div>
          <h1>Filter:</h1>
        </div>
        <div className="flex items-center space-x-2">
          <div>
            <input
              type="text"
              className="bg-gray-100 focus-within:outline-none py-2 px-2 rounded-lg"
              placeholder="Search..."
            />
          </div>
          <button
            onClick={() => setIsToggle(!isToggle)}
            className="flex items-center bg-sw-100 px-4 py-2 rounded-2xl space-x-2 text-white"
          >
            <FiPlusCircle />
            <span>Add</span>
          </button>
        </div>
      </div>

      {/* Table Wrapper with Horizontal Scroll */}
      <div className="w-full overflow-x-auto no-scrollbar">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-white uppercase bg-sw-100/[0.8]">
            <tr>
              <th scope="col" class="px-6 py-3">
                S.No
              </th>
              <th scope="col" class="px-6 py-3">
                Title
              </th>
              <th scope="col" class="px-6 py-3">
                Sub Title
              </th>
              {/* <th scope="col" class="px-6 py-3">
                  Content
                </th> */}
              <th scope="col" class="px-6 py-3">
                CreatedAt
              </th>
              <th scope="col" class="px-6 py-3">
                Status
              </th>
              <th scope="col" class="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>

          <tbody>
            {blogs &&
              blogs.map((item, index) => (
                <tr
                  class={`${
                    (index + 1) % 2 == 0 ? "bg-gray-100" : "bg-gray-50"
                  }`}
                  key={index + 1}
                >
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                  >
                    {index + 1}
                  </th>
                  <td class="px-6 py-4">{item.title}</td>
                  <td class="px-6 py-4">{item.subTitle}</td>
                  {/* <td class="px-6 py-4">{item.html}</td> */}
                  <td class="px-6 py-4">
                    <div>{item.createdAt}</div>
                  </td>
                  <td class="px-6 py-4">
                    <div>
                      <label class="relative inline-flex items-center mr-5 cursor-pointer">
                        <input
                          type="checkbox"
                          value=""
                          class="sr-only peer"
                          onChange={(e) => handleUpdateStatus(e, item?.id)}
                          checked={item?.status === "active" ? true : false}
                        />
                        <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-red-300 dark:peer-focus:ring-red-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-red-600"></div>
                        <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300 capitalize">
                          {item?.status}
                        </span>
                      </label>
                    </div>
                  </td>
                  <td className="px-6 py- space-x-4">
                    <div className="flex space-x-2">
                      <button
                        onClick={() => editHandleClick(item?.id)}
                        class="font-medium"
                      >
                        <FiEdit className="text-md" />
                      </button>
                      <Link to={`/blogs/${item?.id}`} className="font-medium">
                        <BsFillEyeFill className="text-md" />
                      </Link>
                      <button
                        onClick={() => handleDelete(item?.id)}
                        className="font-medium text-sw-100"
                      >
                        <AiFillDelete className="text-md" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        limit={limit}
        setLimit={setLimit}
        handlePageChange={handlePageChange}
      />

      {isToggle && (
        <BlogModel
          isToggle={isToggle}
          setIsToggle={setIsToggle}
          editItem={editData}
        />
      )}
    </div>
  );
};

export default Blogs;
