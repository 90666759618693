import React from "react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import profileLogo from "../../assets/profile_logo.webp";
import { calculateTime } from "../../utils/dateUtils";

const ArtistView = ({}) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const dummyData = {
    id: "13",
    role: "USER",
    firstName: "preet",
    middleName: null,
    lastName: "methew",
    phone: "1122334455",
    email: "deep.artist@gmail.com",
    password: null,
    isBlock: false,
    status: "verified",
    dob: "1996-07-11T00:00:00.000Z",
    gender: "male",
    description: "this is artis",
    expertise: "hair cuting",
    experience: 2,
    address: "delhi",
    locality: "delhi",
    city: "new delhi",
    state: "delhi",
    pincode: "110059",
    country: "india",
    avatar:
      "https://salonwallah.nyc3.digitaloceanspaces.com/files/files__1685802676621.jpg",
    isArtist: true,
    adharImage:
      "https://www.91-cdn.com/hub/wp-content/uploads/2023/03/Aadhaar-verification-how-to.jpg",
    isVerified: false,
    latitude: 28.6390065,
    longitude: 77.0719902,
    isDuty: true,
    createdAt: "2023-08-20T09:31:25.813Z",
    updatedAt: "2023-09-30T12:29:53.776Z",
    deletedAt: null,
    appointments: [
      {
        service: "Hair Coloring",
        date: "22-10-2023",
        time: "11:00 AM",
        location: "Vihar Nagar",
        status: "Active",
        amount: 250,
        vendor: "SalonWallah",
      },
      {
        service: "Hair Coloring",
        date: "22-10-2023",
        time: "11:00 AM",
        location: "Vihar Nagar",
        status: "Cancelled",
        amount: 250,
        vendor: "SalonWallah",
      },
      {
        service: "Hair Coloring",
        date: "22-10-2023",
        time: "11:00 AM",
        location: "Vihar Nagar",
        status: "Done",
        amount: 250,
        vendor: "SalonWallah",
      },
    ],
  };

  return (
    <div className="p-2">
      <div className="flex items-center justify-start space-x-4 border-b-2 py-3">
        <span onClick={() => navigate(-1)}>
          <BiArrowBack className="text-lg cursor-pointer font-bold" />
        </span>
        <p className="text-xs font-medium">
          <span className="bg-red-600 px-2 py-1 text-white rounded-lg capitalize">
            Artist
          </span>
          {" / "}
          <span className="bg-red-600 px-2 py-1 text-white rounded-lg capitalize">
            {dummyData?.firstName} {dummyData?.middleName} {dummyData?.lastName}
          </span>
        </p>
      </div>

      <div className="mt-2 grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 gap-2">
        <div className="col-span-1 p-1 rounded-lg shadow-md h-fit">
          <div className="flex justify-center items-center w-full px-16 sm:px-8 py-4 ">
            {dummyData?.avatar !== null ? (
              <img className="aspect-1 w-full h-full" src={dummyData?.avatar} />
            ) : dummyData?.adharImage !== null ? (
              <img className="w-full h-full aspect-1" />
            ) : (
              <img className="w-full h-full aspect-1" src={profileLogo} />
            )}
          </div>
          <p className="text-center font-semibold text-md capitalize">
            {dummyData?.firstName} {dummyData?.middleName} {dummyData?.lastName}
          </p>

          <div className="flex justify-center my-2">
            <button className="bg-sw-100 text-sm text-white py-1 px-3 rounded-lg shadow-md cursor-pointer">
              BLOCK USER
            </button>
          </div>
        </div>
        <div className="col-span-1 sm:col-span-2 md:col-span-3 xl:col-span-4 p-1 rounded-lg shadow-md">
          <div className="flex flex-col justify-start px-4">
            <span className="text-slate-600 text-sm font-medium">Details</span>
            <hr className="w-full py-1" />
            <div className="grid grid-cols-1 md:grid-cols-2">
              <p>
                <span className="text-sm">Email : </span>
                <span className="text-sm text-slate-600">
                  {dummyData?.email}
                </span>
              </p>
              <p>
                <span className="text-sm">Contact : </span>
                <span className="text-sm text-slate-600">
                  {dummyData?.phone}
                </span>
              </p>
              <p>
                <span className="text-sm">Gender : </span>
                <span className="text-sm text-slate-600">
                  {dummyData?.gender}
                </span>
              </p>
              <p>
                <span className="text-sm">DOB : </span>
                <span className="text-sm text-slate-600">
                  {calculateTime(dummyData?.dob)}
                </span>
              </p>
              <p>
                <span className="text-sm">Status : </span>
                <span className="text-sm text-slate-600 capitalize">
                  {dummyData?.status}
                </span>
              </p>
              <p>
                <span className="text-sm">Address : </span>
                <span className="text-sm text-slate-600 capitalize">
                  {dummyData?.locality}, {dummyData?.address}
                </span>
              </p>
              <p>
                <span className="text-sm">City : </span>
                <span className="text-sm text-slate-600 capitalize">
                  {dummyData?.city}
                </span>
              </p>
              <p>
                <span className="text-sm">State : </span>
                <span className="text-sm text-slate-600 capitalize">
                  {dummyData?.state}
                </span>
              </p>
              <p>
                <span className="text-sm">Pincode : </span>
                <span className="text-sm text-slate-600 capitalize">
                  {dummyData?.pincode}
                </span>
              </p>
              <p>
                <span className="text-sm">Country : </span>
                <span className="text-sm text-slate-600 capitalize">
                  {dummyData?.country}
                </span>
              </p>
              <p>
                <span className="text-sm">Expertise : </span>
                <span className="text-sm text-slate-600 capitalize">
                  {dummyData?.expertise}
                </span>
              </p>
              <p>
                <span className="text-sm">Experience : </span>
                <span className="text-sm text-slate-600 capitalize">
                  {dummyData?.experience}
                </span>
              </p>
              <p>
                <span className="text-sm">Joined On : </span>
                <span className="text-sm text-slate-600 capitalize">
                  {calculateTime(dummyData?.createdAt)}
                </span>
              </p>
              <p>
                <span className="text-sm">Last Updated : </span>
                <span className="text-sm text-slate-600 capitalize">
                  {calculateTime(dummyData?.updatedAt)}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArtistView;
