import React, { useState } from "react";
import { useEffect } from "react";

const CopyableField = ({ code }) => {
  const [copySuccess, setCopySuccess] = useState(false);
  const [textToCopy, setTextToCopy] = useState(null);

  useEffect(() => {
    setTextToCopy(code);
  }, []);

  const handleCopyClick = () => {
    const tempInput = document.createElement("input");
    tempInput.value = textToCopy;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);

    // Set copySuccess to true to display a success message
    setCopySuccess(true);

    // Reset copySuccess after a brief delay
    setTimeout(() => {
      setCopySuccess(false);
    }, 1500);
  };

  return (
    <div
      onClick={handleCopyClick}
      className={`shadow-md py-1 px-2 w-24 rounded-lg text-center ${
        copySuccess && "ring-1 ring-sw-100"
      }`}
    >
      <span className="text-black font-semibold">
        {copySuccess ? "Copied" : textToCopy}
      </span>
      <input type="text" value={textToCopy} readOnly hidden />
    </div>
  );
};

export default CopyableField;
