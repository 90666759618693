import React from "react";
import { IoIosSearch } from "react-icons/io";
import { IoIosNotifications } from "react-icons/io";
import { IoReorderFour } from "react-icons/io5";
import { MdOutlineMessage } from "react-icons/md";
import { Link } from "react-router-dom";

const Header = ({ isDrawer, setIsDrawer }) => {
  return (
    <div className="h-14 bg-sw-100 flex items-center px-2">
      <div className="w-[21%] hidden md:inline-block">
        <Link to={"/"} className="flex space-x-2">
          <div className="h-8 w-8">
            <img
              src="/sw-brand-logo.png"
              className="h-full w-full"
              alt="logo"
            />
          </div>
          <h1 className="text-xl font-medium capitalize px-2 text-white">
            SallonWalla
          </h1>
        </Link>
      </div>
      <div className="flex-1 flex justify-between items-center space-x-4 pr-2">
        <div className="flex-1 flex items-center space-x-2 ">
          <button onClick={() => setIsDrawer(!isDrawer)}>
            <IoReorderFour className="text-3xl text-white" />
          </button>
          <div className="w-[80%] relative">
            <input
              type="text"
              className="py-2 pl-2 capitalize bg-slate-100 w-full rounded-lg focus-within:outline-none"
              placeholder="Search"
            />
            <IoIosSearch size={24} className="absolute right-2 top-2" />
          </div>
        </div>
        <div className="flex space-x-4">
          <button>
            <MdOutlineMessage size={22} className="text-white" />
          </button>
          <button>
            <IoIosNotifications size={22} className="text-white" />
          </button>
          <div>
            <Link to="/settings">
              <div className="h-10 w-10 rounded-full overflow-hidden cursor-pointer hover:border">
                <img
                  src="/assets/sw-wall.png"
                  className="w-full h-full rounded-full"
                  alt="profile"
                />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
