import axios from "axios";

export const AUTH_TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjQ5IiwicGhvbmUiOiI5NzE4NTQ2ODg3Iiwicm9sZSI6IlVTRVIiLCJpc0FydGlzdCI6ZmFsc2UsImlhdCI6MTczNzIxMTE5NiwiZXhwIjoxNzM3Mjk3NTk2fQ.F-mmUpi7M_Ps4-tqCU8zQesKmR7xytOrnd6e6RVpgnw";

export const BASE_URL = process.env.REACT_APP_BASE_URL;
const API = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export default API;
