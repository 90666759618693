import React, { useRef, useCallback } from "react";
import JoditEditor from "jodit-react";

import "./styles.css";

const NewRichTextEditor = ({
  placeholder,
  content,
  setContent,
  toolbarButtonSize = "small",
}) => {
  const editor = useRef(null);

  const config = useCallback(
    {
      buttons: "bold,italic,underline,ul,ol, brush, link, fontsize",
      toolbarButtonSize: "small",
      colorPickerDefaultTab: "color",
      readonly: false,
      toolbarAdaptive: false,
      uploader: {
        insertImageAsBase64URI: true,
      },
      defaultMode: 1,
      askBeforePasteFromWord: false,
      askBeforePasteHTML: false,
      defaultActionOnPaste: "insert_only_text",
      disablePlugins: ["ol", "ul"],
      link: {
        openInNewTabCheckbox: false,
        newFollow: false,
        processVideoLink: false,
        iframe: false,
      },
      events: {
        applyLink: (jodit, link) => {
          link.setAttribute("target", "_blank");
        },
      },
    },
    [(placeholder, toolbarButtonSize)]
  );

  return (
    <>
      <JoditEditor
        ref={editor}
        value={content}
        config={config}
        tabIndex={1} // tabIndex of textarea
        onChange={(newContent) => {
          setContent(newContent.trim());
        }}
      />
    </>
  );
};

export default NewRichTextEditor;
