import React, { useEffect, useState } from "react";
import styles from "./services.module.css";
import { AiFillDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { BsFillEyeFill } from "react-icons/bs";
import { FiPlusCircle } from "react-icons/fi";
import { useStateProvider } from "../../context/StateReducers";
import { reducerCases } from "../../context/constants";
import {
  deleteRecord,
  getRecord,
  getServices,
} from "../../services/services.api";
import ServicesModel from "../../components/models/ServicesModel";
import Pagination from "../../components/form/Pagination";
import { toastMessage } from "../../components/toast/toastMessage";
import { Link } from "react-router-dom";

const Services = () => {
  const [isToggle, setIsToggle] = useState(false);
  const [{ services, userInfo, refresh }, dispatch] = useStateProvider();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(null);
  const [totalPages, setTotalPages] = useState([]);
  const [editData, setEditData] = useState(null);
  const [code, setCode] = useState("");

  useEffect(() => {
    !isToggle && setEditData(null);
  }, [isToggle]);

  useEffect(() => {
    callApis();
  }, [page, limit, userInfo, refresh]);

  async function callApis() {
    if (userInfo) {
      let params = {};
      params.limit = limit;
      params.page = page;
      const { data } = await getServices(
        "/admin/services/list/admin",
        params,
        userInfo?.accessToken
      );

      if (data.success) {
        dispatch({
          type: reducerCases.GET_SERVICES,
          services: data.data.rows,
        });
        setCurrentPage(data.data.currentPage);
        setTotalPages(
          Array.from({ length: data.data.totalPages }, (_, index) => index + 1)
        );
        dispatch({ type: reducerCases.SET_REFRESH, refresh: false });
      }
    }
  }

  const handleDelete = (serviceId) => {
    (async function (serviceId) {
      let params = {};
      const { data } = await deleteRecord(
        `/admin/services/delete/${serviceId}`,
        params,
        userInfo?.accessToken
      );
      if (data.success) {
        dispatch({ type: reducerCases.SET_REFRESH, refresh: true });
        toastMessage(data);
      }
    })(serviceId);
  };

  const editHandleClick = (serviceId) => {
    (async function (serviceId) {
      const query = {};
      const { data } = await getRecord(
        `/admin/services/view/${serviceId}/admin`,
        query,
        userInfo?.accessToken
      );

      if (data.success) {
        setIsToggle(!isToggle);
        setEditData(data?.data);
      } else {
        toastMessage(data);
      }
    })(serviceId);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  return (
    <div
      className={`px-4 h-full overflow-y-auto ${styles.contentBoxScrollbar}`}
    >
      <h1 className="border-b py-2 font-medium text-lg text-gray-600">
        Services
      </h1>

      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-lg font-medium text-gray-700 py-4">Filter:</h1>
        </div>
        <div className="w-[50%] flex space-x-2">
          <div className="flex-1">
            <input
              type="text"
              placeholder="Search..."
              className="border rounded-lg px-4 py-2 w-full focus:outline-none focus:ring-2 focus:ring-sw-100/[0.2]"
            />
          </div>
          <button
            onClick={() => setIsToggle(!isToggle)}
            className="flex items-center bg-sw-100 px-8 py-2 rounded-xl space-x-2 text-white"
          >
            <FiPlusCircle size={18} />
            <span>Add</span>
          </button>
        </div>
      </div>

      {/* Table Wrapper with Horizontal Scroll */}
      <div className="w-full overflow-x-auto no-scrollbar">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-white uppercase bg-sw-100/[0.8]">
            <tr>
              <th scope="col" className="px-6 py-3">
                ID
              </th>
              <th scope="col" className="px-6 py-3">
                Type
              </th>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Category
              </th>
              <th scope="col" className="px-6 py-3">
                Service Type
              </th>
              <th scope="col" className="px-6 py-3">
                Description
              </th>
              <th scope="col" className="px-6 py-3">
                Price
              </th>
              <th scope="col" className="px-6 py-3">
                CreatedAt
              </th>
              <th scope="col" className="px-6 py-3">
                Status
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>

          <tbody>
            {services &&
              services.map((item, idx) => (
                <tr key={idx} className="hover:bg-gray-100 even:bg-gray-50">
                  <td className="px-6 py-4 capitalize">#{idx + 1}</td>
                  <td className="px-6 py-4 capitalize">{item?.type}</td>
                  <td className="px-6 py-4 capitalize">{item?.name}</td>
                  <td className="px-6 py-4 capitalize">
                    {item?.category?.name}
                  </td>
                  <td className="px-6 py-4 capitalize">{item?.serviceType}</td>
                  <td className="px-6 py-4 capitalize min-w-[400px]">
                    {item?.description}
                  </td>
                  <td className="px-6 py-4 capitalize">{item?.price}</td>
                  <td className="px-6 py-4 capitalize">
                    {new Date(item?.createdAt).toLocaleDateString()}
                  </td>
                  <td className="px-6 py-4 capitalize">{item?.status}</td>
                  <td className="px-6 py-4">
                    <div className="flex items-center space-x-2">
                      <button
                        onClick={() => editHandleClick(item?.id)}
                        className="text-blue-500 hover:underline"
                      >
                        <FiEdit className="text-md" />
                      </button>
                      <Link
                        to={`/services/${item?.id}`}
                        className="text-green-500 hover:underline"
                      >
                        <BsFillEyeFill className="text-md" />
                      </Link>

                      <button
                        onClick={() => handleDelete(item?.id)}
                        className="text-red-500 hover:underline"
                      >
                        <AiFillDelete className="text-md" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        limit={limit}
        setLimit={setLimit}
        handlePageChange={handlePageChange}
      />

      {isToggle && (
        <ServicesModel
          isToggle={isToggle}
          setIsToggle={setIsToggle}
          editData={editData}
        />
      )}
    </div>
  );
};

export default Services;
