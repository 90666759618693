import React, { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import styles from "./order.module.css";
import { FaDownload } from "react-icons/fa";
import { useStateProvider } from "../../context/StateReducers";
import { downloadReport, getRecord } from "../../services/services.api";
import { reducerCases } from "../../context/constants";
import Pagination from "../../components/form/Pagination";
import { BsFillEyeFill } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { Link } from "react-router-dom";
import { MOMENT } from "../../utils/dateUtils";

const Orders = () => {
  const [{ orders, userInfo, refresh }, dispatch] = useStateProvider();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(null);
  const [totalPages, setTotalPages] = useState([]);

  useEffect(() => {
    callApis();
  }, [page, limit, userInfo, refresh]);

  async function callApis() {
    if (userInfo) {
      let params = {};
      params.limit = limit;
      params.page = page;
      const { data } = await getRecord(
        "/user/orders/list",
        params,
        userInfo?.accessToken
      );

      if (data.success) {
        dispatch({
          type: reducerCases.GET_ORDERS,
          orders: data.data.rows,
        });
        setCurrentPage(data.data.currentPage);
        setTotalPages(
          Array.from({ length: data.data.totalPages }, (_, index) => index + 1)
        );
        dispatch({ type: reducerCases.SET_REFRESH, refresh: false });
      }
    }
  }

  const [isToggle, setIsToggle] = useState(false);

  const handleDownloadInvoice = async () => {
    const { data } = await downloadReport(
      "/user/orders/list",
      userInfo?.accessToken
    );

    if (data.success) {
      console.log(data.message);
    } else {
      console.log(data.message);
    }
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  return (
    <div className={`px-4 h-full overflow-y-auto ${styles.orderScrollbar}`}>
      {/* Header */}
      <h1 className="text-xl font-bold text-gray-700 mb-2">Orders</h1>

      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-lg font-medium text-gray-700 py-4">Filter:</h1>
        </div>
        <div className="w-[40%]">
          <input
            type="text"
            placeholder="Search..."
            className="border rounded-lg px-4 py-2 w-full focus:outline-none focus:ring-2 focus:ring-sw-100/[0.2]"
          />
        </div>
      </div>

      {/* Table */}
      <div className={`overflow-y-auto ${styles.orderScrollbar}`}>
        <table className="w-full text-sm text-left text-gray-500">
          <thead class="text-xs text-white uppercase bg-sw-100/[0.8]">
            <tr>
              <th scope="col" class="px-6 py-3">
                S.No
              </th>
              <th scope="col" class="px-6 py-3">
                Type
              </th>
              <th scope="col" class="px-6 py-3">
                Username
              </th>
              <th scope="col" class="px-6 py-3">
                qty
              </th>
              <th scope="col" class="px-6 py-3">
                Amount
              </th>
              <th scope="col" class="px-6 py-3">
                bookingDateTime
              </th>
              <th scope="col" class="px-6 py-3">
                isFinished
              </th>
              <th scope="col" class="px-6 py-3">
                createdAt
              </th>
              <th scope="col" class="px-6 py-3">
                status
              </th>
              <th scope="col" class="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {orders &&
              orders.map((item, index) => (
                <tr
                  class={`${
                    (index + 1) % 2 == 0 ? "bg-gray-100" : "bg-gray-50"
                  }`}
                  key={index + 1}
                >
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                  >
                    {index + 1}
                  </th>
                  <td class="px-6 py-4">{item?.type}</td>
                  <td class="px-6 py-4">
                    <div className="w-36">
                      <span>
                        {item?.User?.firstName} {item?.User?.firstName}
                      </span>
                    </div>
                  </td>
                  <td class="px-6 py-4">{item?.qty}</td>
                  <td class="px-6 py-4">
                    <div>{item?.totalPrice}</div>
                  </td>
                  <td class="px-6 py-4">
                    <div className="capitalize w-48">
                      {MOMENT(item?.bookingDateTime)}
                    </div>
                  </td>
                  <td class="px-6 py-4">
                    {item?.isFinished ? (
                      <div>
                        <span className="text-green-700 font-semibold">
                          Finished
                        </span>
                      </div>
                    ) : (
                      <div>
                        <span className="text-sw-100 font-semibold">
                          Processig
                        </span>
                      </div>
                    )}
                  </td>
                  <td class="px-6 py-4">
                    <div className="w-48">{MOMENT(item?.createdAt)}</div>
                  </td>
                  <td class="px-6 py-4">
                    <div>{item?.status}</div>
                  </td>
                  <td class="px-6 py-6">
                    <div className="flex space-x-4">
                      <Link
                        to={`/orders/${item?.id}`}
                        class="font-medium space-x-2 flex items-center"
                      >
                        <BsFillEyeFill className="text-md" />
                        <span>View</span>
                      </Link>
                      <button title="download invoice">
                        <FaDownload size={16} />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <div>
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          limit={limit}
          setLimit={setLimit}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default Orders;
