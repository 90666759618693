import React from "react";
import ReactApexChart from "react-apexcharts";
import cnt from "../../assets/constant.json";
import { getMonthStr } from "../../utils/dateUtils";

export const IncomeExpenseBarChart = () => {
  const data = {
    series: [
      {
        name: "Income",
        data: [
          45000, 67000, 31000, 82000, 23150, 48000, 96000, 23000, 0, 0, 0, 0,
        ],
      },
      {
        name: "Expense",
        data: [
          15000, 17000, 11000, 12000, 13150, 18000, 16000, 13000, 0, 0, 0, 0,
        ],
      },
    ],
    options: {
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              height: 150,
            },
            dataLabels: { enabled: false },
          },
        },
      ],
      chart: {
        type: "bar",
        height: 250,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      yaxis: {
        title: {
          text: "Amount in Rs",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "Rs " + val;
          },
        },
      },
    },
  };

  return (
    <div>
      <ReactApexChart
        options={data.options}
        series={data.series}
        type="bar"
        height={250}
      />
    </div>
  );
};

export const GenderPiChartBox = ({ male = 0, female = 0 }) => {
  const data = {
    series: [male, female],
    options: {
      chart: { type: "pie" },
      labels: ["Male", "Female"],
      legend: {
        position: "bottom",
        horizontalAlign: "center",
        labels: {
          colors: "#333",
          useSeriesColors: true,
        },
      },
    },
  };

  return (
    <ReactApexChart
      options={data.options}
      series={data.series}
      type="pie"
      width={"100%"}
      height={"100%"}
    />
  );
};

export const RevenueChart = () => {
  const data = {
    today: 1000,
    monthly: 50000,
    series: [
      {
        name: "series1",
        data: [31, 40, 28, 51, 42, 109, 100],
      },
    ],
    options: {
      chart: {
        height: 250,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      colors: [cnt.color.primary],
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "datetime",
        categories: [
          "2018-09-19T00:00:00.000Z",
          "2018-09-19T01:30:00.000Z",
          "2018-09-19T02:30:00.000Z",
          "2018-09-19T03:30:00.000Z",
          "2018-09-19T04:30:00.000Z",
          "2018-09-19T05:30:00.000Z",
          "2018-09-19T06:30:00.000Z",
        ],
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    },
  };

  return (
    <ReactApexChart
      options={data.options}
      series={data.series}
      type="area"
      width={"100%"}
      height={"100%"}
    />
  );
};

export const BarChartBox = ({ orderHistory = null }) => {
  const categories = orderHistory?.map((item) => getMonthStr(item.month)) || [];
  const series = orderHistory?.map((item) => item.orderCount) || [];

  const data = {
    options: {
      chart: { id: "Appointment History" },
      xaxis: {
        categories: categories,
      },
      plotOptions: {
        bar: {
          colors: {
            ranges: [
              {
                from: 0,
                to: 30,
                color: "#EC0000", // Color for values between 0 and 30
              },
              {
                from: 31,
                to: 60,
                color: "#33FF57", // Color for values between 31 and 60
              },
              {
                from: 61,
                to: 90,
                color: "#5733FF", // Color for values between 61 and 90
              },
              {
                from: 91,
                to: 125,
                color: "#33FFCC", // Color for values between 91 and 125
              },
            ],
            backgroundBarColors: [],
          },
        },
      },
    },
    series: [
      {
        name: "Total Bookings",
        data: series,
      },
    ],
  };

  return (
    <ReactApexChart
      options={data.options}
      series={data.series}
      type="bar"
      width="100%"
      height={190}
    />
  );
};
