import React, { useState, useEffect } from "react";
import { AiOutlineCloudUpload, AiOutlinePlusSquare } from "react-icons/ai";
import { GrGallery } from "react-icons/gr";
import { FaTimes } from "react-icons/fa";
import Styles from "../../components/form/scrollbar.module.css";
import { UploadFile } from "../../services/auth.api";
import { useStateProvider } from "../../context/StateReducers";
import ImageViewer, { BrowseImages } from "../../components/form/ImageViewer";
import { useForm } from "react-hook-form";
import { AddRecord, UpdateRecord } from "../../services/services.api";
import { toastMessage } from "../../components/toast/toastMessage";
import { reducerCases } from "../../context/constants";

const GalleryModel = ({ isToggle, setIsToggle, category }) => {
  const [fileDataURL, setFileDataURL] = useState(null);
  const [fileId, setFileId] = useState(null);
  const [{ userInfo }, dispatch] = useStateProvider();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    category && setFileDataURL([category?.icon]);
  }, []);

  const UploadFiles = (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("type", "gallery");
    (async function () {
      const { data } = await UploadFile(
        "/admin/files/bucket",
        formData,
        userInfo?.accessToken
      );
      if (data.success) {
        if (fileDataURL) {
          const updateFiles = [data.data.url];
          setFileDataURL(updateFiles);
        } else {
          const updateFiles = [data.data.url];
          setFileId(data.data.id);
          setFileDataURL(updateFiles);
        }
      }
    })();
  };

  const handleImages = (item) => {
    const updateFiles =
      fileDataURL && fileDataURL.filter((file) => file !== item);

    updateFiles.length !== 0
      ? setFileDataURL(updateFiles)
      : setFileDataURL(null);
  };

  const createData = (payload) => {
    if (!fileId) return;
    payload.file = fileId && fileId;
    if (payload.tags.length > 0) {
      payload.tags = [payload.tags];
    } else {
      delete payload.tags;
    }

    if (payload.title.length === 0) {
      delete payload.title;
    }

    if (payload.location.length === 0) {
      delete payload.location;
    }

    callApis(payload);
  };

  async function callApis(payload) {
    const { data } = await AddRecord(
      "/admin/gallery/add",
      payload,
      userInfo?.accessToken
    );

    if (data.success) {
      toastMessage(data);
      dispatch({ type: reducerCases.SET_REFRESH, refresh: true });
      setIsToggle(false);
    } else {
      toastMessage(data);
    }
    // }
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center backdrop-blur-sm z-20 w-full border border-slate-400">
      <div className={`w-[100%] h-full bg-gray-100/[0.6]`}>
        <div className="w-full h-full flex justify-center items-center">
          <div className="w-[96%] sm:w-[74%] md:w-[64%] lg:w-[80%] h-[90vh] shadow-md border text-gray-900 bg-red-500 rounded-lg px-2 py-2 relative">
            <FaTimes
              onClick={() => setIsToggle(!isToggle)}
              className="absolute right-5 top-5 text-2xl cursor-pointer text-sw-100"
            />

            <div className="px-10 h-full">
              <form
                onSubmit={handleSubmit((data) => createData(data))}
                className="h-full"
              >
                {fileDataURL && (
                  <>
                    <div className="flex justify-between h-[calc(100%-10%)] w-full">
                      <div className="h-full  w-[40%]">
                        <BrowseImages
                          fileDataURL={fileDataURL}
                          handleImages={handleImages}
                        />
                      </div>
                      <div className="w-[60%] h-full flex items-center">
                        <div className="w-full px-4 space-y-2">
                          <div className="flex flex-col w-full space-y-2">
                            <span className="text-white">Type {`*`}</span>
                            <select
                              {...register("type")}
                              className="w-full h-10 rounded-md pl-2 bg-gray-100 focus:outline-none"
                            >
                              <option value="service" selected>
                                Service
                              </option>
                              <option value="category">Category</option>
                              <option value="package">Package</option>
                              <option value="coupon">Coupon</option>
                              <option value="blog">Blog</option>
                              <option value="banner">Banner</option>
                            </select>
                          </div>

                          <div className="flex flex-col w-full space-y-2">
                            <span className="text-white">
                              Title {`(Optional)`}
                            </span>
                            <input
                              type="text"
                              {...register("title")}
                              className="w-full h-10 rounded-md pl-2 bg-gray-100 focus:outline-none"
                              placeholder="Enter title?"
                            />
                          </div>

                          <div className="flex flex-col w-full space-y-2">
                            <span className="text-white">
                              Tags {`(Optional)`}
                            </span>
                            <input
                              type="text"
                              {...register("tags")}
                              className="w-full h-10 rounded-md pl-2 bg-gray-100 focus:outline-none"
                              placeholder="Enter title?"
                            />
                          </div>

                          <div className="flex flex-col w-full space-y-2">
                            <span className="text-white">
                              Location {`(Optional)`}
                            </span>
                            <input
                              type="text"
                              {...register("location")}
                              className="w-full h-10 rounded-md pl-2 bg-gray-100 focus:outline-none"
                              placeholder="Enter title?"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="h-[10%] flex justify-end items-center space-x-2">
                      <button
                        onClick={() => setIsToggle(!isToggle)}
                        className="bg-yellow-400 px-6 text-white"
                      >
                        CANCEL
                      </button>
                      <button
                        type="submit"
                        className="bg-green-700 px-6 text-white"
                      >
                        SAVE
                      </button>
                    </div>
                  </>
                )}

                {!fileDataURL && (
                  <div className="flex justify-center items-center h-full">
                    <div className="flex flex-col items-center space-y-4">
                      <span>
                        <GrGallery className="text-7xl text-sw-100" />
                      </span>
                      <span className="text-2xl font-semibold text-gray-800">
                        Drog and drop to Upload
                      </span>
                      <label
                        for="dropzone-file"
                        className="bg-green-800 px-12 py-2 rounded-lg cursor-pointer text-white font-semibold"
                      >
                        <span>Browse</span>
                        <input
                          onChange={(e) => UploadFiles(e)}
                          accept="image/*"
                          id="dropzone-file"
                          type="file"
                          className="hidden"
                        />
                      </label>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GalleryModel;
