import React, { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { MdCategory, MdDescription, MdVerified } from "react-icons/md";
import { GiDuration } from "react-icons/gi";
import { useNavigate, useParams } from "react-router-dom";
import { getServices } from "../../services/services.api";
import { useStateProvider } from "../../context/StateReducers";
import { reducerCases } from "../../context/constants";
import { toastMessage } from "../../components/toast/toastMessage";
import { calculateTime } from "../../utils/dateUtils";
import { BsCurrencyRupee } from "react-icons/bs";
import styles from "./services.module.css";
import { IoTimeSharp } from "react-icons/io5";

const ServicesView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [{ userInfo, category }, dispatch] = useStateProvider();
  const [coverImage, setCoverImage] = useState(null);

  useEffect(() => {
    if (userInfo) {
      callApi();
    }
  }, [userInfo]);

  const callApi = async () => {
    const query = {};
    const { data } = await getServices(
      `/admin/services/view/${id}/admin`,
      query,
      userInfo?.accessToken
    );

    console.log({ data });

    if (data.success) {
      dispatch({ type: reducerCases.VIEW_CATEGORY, category: data?.data });
    } else {
      toastMessage(data);
    }
  };

  return (
    <div className="px-2 py-2">
      <div className="flex items-center justify-start space-x-4 border-b-2 py-3">
        <span onClick={() => navigate(-1)}>
          <BiArrowBack className="text-lg cursor-pointer font-bold" />
        </span>
        <p className="text-xs font-medium">
          <span className="bg-red-600 px-2 py-1 text-white rounded-lg capitalize">
            Services
          </span>{" "}
          {"/"}{" "}
          <span className="bg-red-600 px-2 py-1 text-white rounded-lg capitalize">
            {category?.name}
          </span>
        </p>
      </div>

      <div className="my-6">
        <div className="flex flex-wrap justify-between space-x-4">
          <div className="w-full md:flex-1">
            <div className="h-80 rounded-lg shadow-md ring-1 ring-gray-100">
              <img
                className="h-full w-full rounded-lg"
                src={coverImage ? coverImage : category?.urls[0]}
                alt="icon"
              />
            </div>

            <div
              className={`px-2 py-2 overflow-x-auto ${styles.ImageScrollerContainer} `}
            >
              <div className="flex space-x-2 cursor-pointer">
                {category &&
                  category?.urls.map((url) => (
                    <div className="h-16 w-20 flex-shrink-0">
                      <img
                        onClick={() => setCoverImage(url)}
                        className="h-full w-full rounded-lg"
                        src={url}
                        alt="icon"
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className="w-full md:flex-1">
            <div className="flex items-center space-x-2">
              <span>
                {category?.status === "pending" && (
                  <MdVerified className="text-green-600 font-bold text-lg" />
                )}
              </span>
              <p className="text-lg font-semibold capitalize">
                {category?.name} {"< "}
                <span className="text-sm text-sw-100">{category?.type}</span>
                {" >"}
              </p>
            </div>

            <div className="pb-3 flex items-center space-x-2">
              <span>
                <MdCategory className="text-lg" />
              </span>
              <span className="text-gray-700 font-semibold text-lg">
                Category :{" "}
              </span>
              <span className="text-gray-500 capitalize text-md">
                {category?.category?.name}
              </span>
            </div>

            <div className="w-full">
              <div className="flex items-center space-x-2">
                <span>
                  <MdDescription className="text-lg" />
                </span>
                <span className="text-lg font-medium text-gray-700">
                  Description{" "}
                </span>
              </div>

              <div className="pl-4 w-[92%]">
                <p className="text-sm font-normal w-fit text-gray-600 capitalize px-1 rounded-md bg-slate-200">
                  {category?.description}
                </p>
              </div>

              <div className="flex items-center space-x-3 mt-4">
                <div className="flex items-center space-x-2">
                  <span>
                    <GiDuration className="text-lg" />
                  </span>
                  <span className="text-sw-100 font-bold">
                    {category?.duration}
                  </span>
                </div>
                <div className="flex items-center">
                  <span>
                    <BsCurrencyRupee className="text-lg" />
                  </span>
                  <span className="font-bold text-black">
                    {category?.price}
                  </span>
                </div>
              </div>

              <p className="py-4 text-md text-gray-500">
                <div className="flex items-center">
                  <IoTimeSharp className="text-lg text-gray-700" />
                  <span className="block text-lg pl-2 font-medium text-gray-700">
                    Created At :{" "}
                  </span>
                  <span className="pl-1">
                    {calculateTime(category?.createdAt)}
                  </span>
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesView;
