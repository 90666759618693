import { reducerCases } from "./constants";

export const initialState = {
  userInfo: undefined,
  services: [],
  categories: [],
  orders: [],
  category: undefined,
  coupon: undefined,
  dashboard: undefined,
  withdrawal_requests: [],
  refresh: false,
  packages: [],
  package: [],
  users: null,
  transactions: null,
  vendors: null,
  coupons: null,
  blogs: null,
  blog: null,
  gallery: null,
  galleryView: null,
  loadMore: false,
  upcoming_appointment: null,
  queries: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case reducerCases.UPCOMING_APPOINTMENT:
      return {
        ...state,
        upcoming_appointment: action.upcoming_appointment,
      };
    case reducerCases.SET_USER_INFO:
      return {
        ...state,
        userInfo: action.userInfo,
      };
    case reducerCases.GET_SERVICES:
      return {
        ...state,
        services: action.services,
      };
    case reducerCases.GET_CATEGORIES:
      return {
        ...state,
        categories: action.categories,
      };
    case reducerCases.VIEW_CATEGORY:
      return {
        ...state,
        category: action.category,
      };
    case reducerCases.DASHBOARD:
      return {
        ...state,
        dashboard: action.dashboard,
      };
    case reducerCases.GET_WITHDRAWAL_REQUESTS:
      return {
        ...state,
        withdrawal_requests: action.withdrawal_requests,
      };
    case reducerCases.SET_REFRESH:
      return {
        ...state,
        refresh: action.refresh,
      };
    case reducerCases.SET_PACKAGES:
      return {
        ...state,
        packages: action.packages,
      };
    case reducerCases.VIEW_PACKAGE:
      return {
        ...state,
        packageView: action.packageView,
      };
    case reducerCases.SET_VENDORS:
      return {
        ...state,
        vendors: action.vendors,
      };
    case reducerCases.GET_COUPONS:
      return {
        ...state,
        coupons: action.coupons,
      };
    case reducerCases.VIEW_COUPON:
      return {
        ...state,
        coupon: action.coupon,
      };
    case reducerCases.GET_ORDERS:
      return {
        ...state,
        orders: action.orders,
      };
    case reducerCases.GET_BLOGS:
      return {
        ...state,
        blogs: action.blogs,
      };
    case reducerCases.VIEW_BLOGS:
      return {
        ...state,
        blog: action.blog,
      };
    case reducerCases.GET_GELLARY:
      return {
        ...state,
        gallery: action.gallery,
      };
    case reducerCases.VIEW_GELLARY:
      return {
        ...state,
        galleryView: action.galleryView,
      };
    case reducerCases.LOAD_MORE:
      return {
        ...state,
        loadMore: action.loadMore,
      };
    case reducerCases.GET_QUERIES:
      return {
        ...state,
        queries: action.queries,
      };
    case reducerCases.GET_USERS:
      return {
        ...state,
        users: action.users,
      };
    case reducerCases.GET_TRANSACTIONS:
      return {
        ...state,
        transactions: action.transactions,
      };
    default:
      return state;
  }
};

export default reducer;
