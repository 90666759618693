import { toast } from "react-toastify";

export function capitalizeFirstLetter(string) {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
}

export const toastMessage = (response) => {
  if (response?.success) {
    toast.success(capitalizeFirstLetter(response?.message), {
      position: "top-right",
      theme: "dark",
      autoClose: 2000,
    });
  } else {
    toast.error(capitalizeFirstLetter(response?.message), {
      position: "top-right",
      theme: "dark",
      autoClose: 2000,
    });
  }
};
