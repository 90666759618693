import React from "react";
import { useForm } from "react-hook-form";
import { toastMessage } from "../toast/toastMessage";

const ChangePasswordForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleFormSubmit = (data) => {
    console.log(data);
    if (data.new_password === data.confirm_password) {
      toastMessage({ success: true, message: "Password matches" });
    } else {
      toastMessage({ success: false, message: "Passwords dont match" });
    }
  };

  return (
    <div className="border shadow-md p-2">
      <h4 className="font-semibold text-lg mb-2 text-gray-500">
        Change Password
      </h4>
      <form onSubmit={handleSubmit((data) => handleFormSubmit(data))}>
        <div className="flex flex-col w-full space-y-4">
          <div className="flex flex-col space-y-2">
            <span>New Password</span>
            <input
              required
              type="password"
              name="password"
              className="pl-2 rounded-lg py-1 focus:outline-none bg-gray-200 w-full"
              {...register("password", { required: true })}
              placeholder="***************"
            />
          </div>

          <div className="flex flex-col space-y-2">
            <span>Confirm Password</span>
            <input
              required
              type="password"
              name="password"
              className="pl-2 rounded-lg py-1 focus:outline-none bg-gray-200 w-full"
              {...register("confirmPassword", { required: true })}
              placeholder="***************"
            />
          </div>

          <button
            className="bg-sw-100 p-2 rounded-lg text-white text-sm shadow-md hover:shadow-lg"
            type="submit"
          >
            Change Password
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChangePasswordForm;
