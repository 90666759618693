import React, { useEffect, useState } from "react";
import styles from "./trans.module.css";
import { MdFileDownload } from "react-icons/md";
import Pagination from "../../components/form/Pagination";
import { useStateProvider } from "../../context/StateReducers";
import { downloadReport, getRecord } from "../../services/services.api";
import { reducerCases } from "../../context/constants";
import { MOMENT } from "../../utils/dateUtils";
import { toastMessage } from "../../components/toast/toastMessage";

const Transactions = () => {
  const [activeTab, setActiveTab] = useState("bookings");
  const [searchQuery, setSearchQuery] = useState("");
  const tabs = ["bookings", "payouts"];
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(null);
  const [totalPages, setTotalPages] = useState([]);

  const [{ transactions, userInfo, refresh }, dispatch] = useStateProvider();

  useEffect(() => {
    callApis();
  }, [page, limit, userInfo, refresh]);

  async function callApis() {
    if (userInfo) {
      let params = {};
      params.limit = limit;
      params.page = page;
      const { data } = await getRecord(
        "/user/orders/transactions",
        params,
        userInfo?.accessToken
      );

      if (data.success) {
        dispatch({
          type: reducerCases.GET_TRANSACTIONS,
          transactions: data.data.rows,
        });
        setCurrentPage(data.data.currentPage);
        setTotalPages(
          Array.from({ length: data.data.totalPages }, (_, index) => index + 1)
        );
        dispatch({ type: reducerCases.SET_REFRESH, refresh: false });
      }
    }
  }

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleDownloadInvoice = async (id) => {
    if (userInfo) {
      const response = await downloadReport(
        "https://api.salonwalla.com/downloadPdf",
        userInfo?.accessToken
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "invoice.pdf"); // The file name you want to save it as
      document.body.appendChild(link);
      link.click();

      // Clean up the link element
      link.parentNode.removeChild(link);
    }
  };

  return (
    <div className={`px-4 h-full overflow-y-auto ${styles.orderScrollbar}`}>
      {/* Header */}
      <h1 className="text-xl font-bold text-gray-700 mb-2">Payments</h1>

      {/* Tabs */}
      <div className="flex space-x-4 border-b mb-4">
        {tabs.map((tab, idx) => (
          <button
            key={idx}
            className={`px-4 py-2 font-semibold capitalize ${
              activeTab === tab
                ? "text-blue-600 border-b-2 border-blue-600"
                : "text-gray-600"
            }`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>

      {/* Search */}
      <div className="mb-4 flex justify-end">
        <input
          type="text"
          placeholder="Search queries..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="border rounded-lg px-4 py-2 w-full max-w-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      {/* Table */}
      <div className="overflow-x-auto">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-white uppercase bg-sw-100/[0.8]">
            <tr className="uppercase">
              <th scope="col" className="px-6 py-3 whitespace-nowrap">
                Order Id
              </th>
              <th scope="col" className="px-6 py-3">
                Invoice No
              </th>
              <th scope="col" className="px-6 py-3">
                Payment Date
              </th>
              <th scope="col" className="px-6 py-3">
                Method
              </th>
              <th scope="col" className="px-6 py-3 whitespace-nowrap">
                Payment Status
              </th>
              <th scope="col" className="px-6 py-3">
                Fees
              </th>
              <th scope="col" className="px-6 py-3">
                Amount
              </th>
              <th scope="col" className="px-6 py-3">
                <button className="bg-slate-50 text-sw-100 px-2 py-1 rounded-md whitespace-nowrap">
                  Download All
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {transactions &&
              transactions.map((item, idx) => (
                <tr key={idx} className="hover:bg-gray-100 even:bg-gray-50">
                  <td className="px-6 py-4 capitalize">#{item?.id}</td>
                  <td className="px-6 py-4 capitalize">
                    {item?.transactionId}
                  </td>
                  <td className="px-6 py-4 capitalize">
                    {MOMENT(item?.createdAt)}
                  </td>
                  <td className="px-6 py-4 capitalize">
                    {item?.PaymentMethod}
                  </td>
                  <td className="px-6 py-4 capitalize">
                    {item?.paymentStatus}
                  </td>
                  <td className="px-6 py-4 capitalize">
                    <span className="text-sw-100">Rs. {item?.fee}</span>
                  </td>
                  <td className="px-6 py-4 capitalize">
                    <span>Rs. {item?.totalPrice}</span>
                  </td>
                  <td className="px-6 py-4 capitalize">
                    <div className="flex items-center space-x-4">
                      <button className="text-base font-medium capitalize text-sw-100">
                        <span>View</span>
                      </button>
                      <button
                        onClick={() => handleDownloadInvoice(item?.id)}
                        className="text-base font-medium capitalize text-gray-600"
                      >
                        <MdFileDownload
                          size={18}
                          className="hover:text-gray-800"
                        />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div>
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          limit={limit}
          setLimit={setLimit}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default Transactions;
