import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import Styles from "../../components/form/scrollbar.module.css";
import { useStateProvider } from "../../context/StateReducers";
import { useForm } from "react-hook-form";
import { AddRecord, UpdateRecord } from "../../services/services.api";
import { toastMessage } from "../../components/toast/toastMessage";
import { reducerCases } from "../../context/constants";
import MyDatePicker from "../../components/form/MyDatePicker";
import { saveFormat } from "../../utils/dateUtils";

const CouponsModel = ({ isToggle, setIsToggle, editItem }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedCouponType, setSelectedCouponType] = useState(null);
  const [{ userInfo }, dispatch] = useStateProvider();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    editItem && setSelectedCouponType(editItem?.type.toLowerCase());
    editItem && setSelectedDate(new Date(editItem?.validUntil));
  }, []);

  const createData = (payload) => {
    payload.validUntil = selectedDate && saveFormat(selectedDate);
    callApis(payload);
  };

  async function callApis(payload) {
    if (editItem) {
      const { data } = await UpdateRecord(
        `/coupons/update/${editItem?.id}`,
        payload,
        userInfo?.accessToken
      );

      if (data.success) {
        toastMessage(data);
        dispatch({ type: reducerCases.SET_REFRESH, refresh: true });
        setIsToggle(false);
      } else {
        toastMessage(data);
      }
    } else {
      const { data } = await AddRecord(
        "/coupons/add",
        payload,
        userInfo?.accessToken
      );

      if (data.success) {
        toastMessage(data);
        dispatch({ type: reducerCases.SET_REFRESH, refresh: true });
        setIsToggle(false);
      } else {
        toastMessage(data);
      }
    }
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center backdrop-blur-sm z-20 w-full border border-slate-400">
      <div className={`w-[100%] h-full bg-gray-100/[0.6]`}>
        <div className="w-full h-full flex justify-center items-center">
          <div className="w-[96%] sm:w-[74%] md:w-[64%] lg:w-[50%] h-[90vh] shadow-md border text-gray-900 bg-red-400 rounded-lg px-2 py-2 relative">
            <FaTimes
              onClick={() => setIsToggle(!isToggle)}
              className="absolute right-5 top-5 text-2xl cursor-pointer text-sw-100"
            />
            <form
              onSubmit={handleSubmit((data) => createData(data))}
              className="h-[100%] w-full"
            >
              <div className="h-[calc(10%)] w-full">
                <h1 className="text-2xl font-bold text-center text-white uppercase border-b-2 py-2 mb-4">
                  {editItem ? "Edit Coupons" : "Coupons"}
                </h1>
              </div>

              <div
                className={`h-[calc(100%-20%)] w-[100%] overflow-y-auto ${Styles.editorContainerModel}`}
              >
                <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-2 mb-2">
                  <div className="flex flex-col space-y-1">
                    <span className="text-md font-semibold capitalize">
                      Coupon Type
                    </span>
                    <select
                      {...register("type", {
                        value: editItem && editItem?.type.toLowerCase(),
                        required: true,
                      })}
                      onChange={(e) => setSelectedCouponType(e.target.value)}
                      className="p-1 rounded-md shadow-md capitalize focus:outline-none hover:cursor-pointer"
                    >
                      <option className="text-sm" value="">
                        Select Type
                      </option>
                      <option className="text-sm" value="discount">
                        Discount
                      </option>
                      <option className="text-sm" value="invite">
                        Invites
                      </option>
                      <option className="text-sm" value="offers">
                        Offers
                      </option>
                      <option className="text-sm" value="bookings">
                        bookings
                      </option>
                    </select>
                    {errors.type && (
                      <span className="text-sm text-sw-100">
                        Type is required
                      </span>
                    )}
                  </div>
                  <div className="flex flex-col space-y-1">
                    <span className="text-md font-semibold capitalize">
                      Valid Until
                    </span>
                    <MyDatePicker
                      selectedDate={selectedDate}
                      setSelectedDate={setSelectedDate}
                    />
                  </div>
                </div>

                {selectedCouponType &&
                (selectedCouponType === "invite" ||
                  selectedCouponType === "bookings") ? (
                  <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-2 mb-2">
                    <div className="flex flex-col w-full space-y-1">
                      <span className="text-md font-semibold capitalize">
                        Price
                      </span>
                      <input
                        {...register("price", {
                          value: editItem && editItem?.price,
                          required: true,
                        })}
                        className="p-1 rounded-md shadow-md capitalize focus:outline-none"
                        type="text"
                        placeholder="Enter price"
                      />
                      {errors.price && (
                        <span className="text-sm text-sw-100">
                          Price is Required
                        </span>
                      )}
                    </div>

                    <div className="flex flex-col w-full space-y-1">
                      <span className="text-md font-semibold capitalize">
                        Booking Ranges Amount
                      </span>
                      <input
                        {...register("bookingApplyRanges", {
                          value: editItem && editItem?.bookingApplyRanges,
                          required: true,
                        })}
                        className="p-1 rounded-md shadow-md capitalize focus:outline-none"
                        type="number"
                        placeholder="Enter bookingApplyRanges"
                      />
                      {errors.price && (
                        <span className="text-sm text-sw-100">
                          price is required
                        </span>
                      )}
                    </div>
                  </div>
                ) : selectedCouponType &&
                  (selectedCouponType === "discount" ||
                    selectedCouponType === "offers") ? (
                  <div className="flex w-full space-x-2 mb-2">
                    <div className="flex flex-col w-full space-y-1">
                      <span className="text-md font-semibold capitalize">
                        {selectedCouponType}{" "}
                      </span>
                      <input
                        {...register("discount", {
                          value: editItem && editItem?.discount,
                          required: true,
                        })}
                        className="p-1 rounded-md shadow-md capitalize focus:outline-none"
                        type="text"
                        placeholder={`Enter ${selectedCouponType}`}
                        required={true}
                      />
                      {errors.discount && (
                        <span className="text-sm text-sw-100">
                          discount is required
                        </span>
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="flex flex-col space-y-1 mt-2">
                  <span className="text-md font-semibold capitalize">
                    Desciption
                  </span>
                  <textarea
                    {...register("desciption", {
                      value: editItem && editItem?.desciption,
                      required: true,
                    })}
                    className="p-2 rounded-md shadow-md capitalize focus:outline-none"
                    cols="30"
                    rows="5"
                    placeholder="Write...."
                  ></textarea>
                  {errors.desciption && (
                    <span className="text-sm text-sw-100">
                      Description is required
                    </span>
                  )}
                </div>
              </div>

              <div className="flex justify-end items-center py-4 border-t h-[calc(10%)]">
                <div className="flex justify-between items-center space-x-2">
                  <button
                    type="reset"
                    onClick={() => setIsToggle(!isToggle)}
                    className="bg-gray-200 text-gray-800 font-semibold w-fit py-1 px-2 rounded-lg text-md"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="bg-green-800 text-white font-semibold w-fit py-1 px-2 rounded-lg text-md"
                  >
                    {editItem ? "Save changes" : "Save"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CouponsModel;
