import React, { useEffect, useRef, useState } from "react";
import { FaHome } from "react-icons/fa";
import { HiMiniUsers } from "react-icons/hi2";
import { GrGallery } from "react-icons/gr";
import { BiTransfer } from "react-icons/bi";
import { IoMdImages } from "react-icons/io";
import {
  MdCategory,
  MdEmail,
  MdMiscellaneousServices,
  MdOutlineRequestQuote,
  MdWorkHistory,
} from "react-icons/md";
import { BsPencilSquare } from "react-icons/bs";
import { AiFillGift, AiFillSetting } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { useStateProvider } from "../context/StateReducers";
import { BiSolidCoupon } from "react-icons/bi";

const Sidebar = () => {
  const [{ userInfo }] = useStateProvider();
  const [navItems, setNavitems] = useState([
    {
      name: "Home",
      to: "/dashboard",
      icon: FaHome,
      active: false,
    },
    {
      name: "Services",
      to: "/services",
      icon: MdMiscellaneousServices,
      active: false,
    },
    {
      name: "Category",
      to: "/category",
      icon: MdCategory,
      active: false,
    },
    {
      name: "Packages",
      to: "/packages",
      icon: AiFillGift,
      active: false,
    },
    {
      name: "Users",
      to: "/users",
      icon: HiMiniUsers,
      active: false,
    },
    {
      name: "Coupons",
      to: "/coupons",
      icon: BiSolidCoupon,
      active: false,
    },
    {
      name: "Requests",
      to: "/requests",
      icon: MdOutlineRequestQuote,
      active: false,
    },
    {
      name: "Orders",
      to: "/orders",
      icon: MdWorkHistory,
      active: false,
    },
    {
      name: "Transactions",
      to: "/transactions",
      icon: BiTransfer,
      active: false,
    },
    {
      name: "Query",
      to: "/query",
      icon: MdEmail,
      active: false,
    },
    {
      name: "Blogs",
      to: "/blogs",
      icon: BsPencilSquare,
      active: false,
    },
    {
      name: "Gallery",
      to: "/gallery",
      icon: IoMdImages,
      active: false,
    },
    {
      name: "Settings",
      to: "/settings",
      icon: AiFillSetting,
      active: false,
    },
  ]);

  return (
    <>
      <ul className="px-4 py-4 space-y-2 overflow-hidden">
        {navItems.map((item, idx) => (
          <li key={idx}>
            <NavLink
              to={item.to}
              className="flex items-center justify-center md:justify-start space-x-2"
            >
              <item.icon className="text-2xl md:text-xl text-slate-50" />
              <span className="text-slate-50 font-medium text-lg hidden md:inline-block">
                {item.name}
              </span>
            </NavLink>
          </li>
        ))}
      </ul>
    </>
  );
};

export default Sidebar;
