import React, { useState, useEffect } from "react";
import { AiOutlineCloudUpload, AiOutlinePlusSquare } from "react-icons/ai";
import { FaTimes } from "react-icons/fa";
import Styles from "../../components/form/scrollbar.module.css";
import { UploadFile } from "../../services/auth.api";
import { useStateProvider } from "../../context/StateReducers";
import ImageViewer from "../../components/form/ImageViewer";
import { useForm } from "react-hook-form";
import { AddRecord, UpdateRecord } from "../../services/services.api";
import { toastMessage } from "../../components/toast/toastMessage";
import NewRichTextEditor from "../../components/TextEditor/NewRichTextEditor";
import { reducerCases } from "../../context/constants";

const BlogModel = ({ isToggle, setIsToggle, editItem }) => {
  const [fileDataURL, setFileDataURL] = useState(null);
  const [editorContent, setEditorContent] = useState("");
  const [{ userInfo }, dispatch] = useStateProvider();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    editItem && setEditorContent(editItem?.html);
    editItem && setFileDataURL(editItem?.gallery);
  }, []);

  const UploadFiles = (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    (async function () {
      const { data } = await UploadFile(
        "/admin/files/bucket",
        formData,
        userInfo?.accessToken
      );
      if (data.success) {
        if (fileDataURL) {
          const updateFiles = [...fileDataURL, data.data.url];
          setFileDataURL(updateFiles);
        } else {
          const updateFiles = [data.data.url];
          setFileDataURL(updateFiles);
        }
      }
    })();
  };

  const handleImages = (item) => {
    const updateFiles =
      fileDataURL && fileDataURL.filter((file) => file !== item);

    updateFiles.length !== 0
      ? setFileDataURL(updateFiles)
      : setFileDataURL(null);
  };

  const createData = (payload) => {
    if (!editItem) {
      payload.coverImage = fileDataURL && fileDataURL[0];
    }
    payload.gallery = fileDataURL && fileDataURL;
    payload.html = editorContent;
    console.log(JSON.stringify(payload.html));
    callApis(payload);
  };

  async function callApis(payload) {
    if (editItem) {
      const { data } = await UpdateRecord(
        `/admin/blogs/update/${editItem?.id}`,
        payload,
        userInfo?.accessToken
      );
      if (data.success) {
        toastMessage(data);
        dispatch({ type: reducerCases.SET_REFRESH, refresh: true });
        setIsToggle(false);
      } else {
        toastMessage(data);
      }
    } else {
      const { data } = await AddRecord(
        "/admin/blogs/add",
        payload,
        userInfo?.accessToken
      );

      if (data.success) {
        toastMessage(data);
        setIsToggle(false);
      } else {
        toastMessage(data);
      }
    }
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center backdrop-blur-sm z-20 w-full border border-slate-400">
      <div className={`w-[100%] h-full bg-gray-100/[0.6]`}>
        <div className="w-full h-full flex justify-center items-center">
          <div className="w-[100%] h-full bg-red-400 text-gray-900  rounded-lg px-2 py-2 relative">
            <FaTimes
              onClick={() => setIsToggle(!isToggle)}
              className="absolute right-5 top-5 text-2xl cursor-pointer text-sw-100"
            />
            <form
              onSubmit={handleSubmit((data) => createData(data))}
              className="h-[100%]"
            >
              <div className="h-[calc(10%)] w-full">
                <h1 className="text-2xl font-bold text-center text-white uppercase border-b-2 py-2 mb-4">
                  New Blog
                </h1>
              </div>

              <div
                className={`h-[calc(100%-20%)] flex justify-between space-x-4 overflow-y-auto ${Styles.editorContainerModel}`}
              >
                <div className="w-full flex flex-col justify-between mb-4">
                  <div className="flex flex-col space-y-2 w-full mb-2">
                    <div className="flex flex-col w-full space-y-1">
                      <span className="text-md font-semibold capitalize">
                        Title
                      </span>
                      <input
                        {...register("title", {
                          value: editItem && editItem?.title,
                          required: true,
                        })}
                        className="p-1 rounded-md shadow-md capitalize focus:outline-none"
                        type="text"
                        placeholder="Enter title"
                        required={true}
                      />
                      {errors.name && (
                        <span className="text-sm text-sw-100">
                          Type is required
                        </span>
                      )}
                    </div>
                    <div className="flex flex-col w-full space-y-1">
                      <span className="text-md font-semibold capitalize">
                        Sub Title
                      </span>
                      <input
                        {...register("subTitle", {
                          value: editItem && editItem?.subTitle,
                          required: true,
                        })}
                        className="p-1 rounded-md shadow-md capitalize focus:outline-none"
                        type="text"
                        placeholder="Enter sub title"
                        required={true}
                      />
                      {errors.name && (
                        <span className="text-sm text-sw-100">
                          Type is required
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="flex flex-col gap-2">
                    <span className="text-md font-semibold capitalize">
                      Title
                    </span>
                    <div className="flex flex-col h-full">
                      <NewRichTextEditor
                        placeholder={"Write text here"}
                        content={editorContent}
                        setContent={setEditorContent}
                      />
                    </div>
                  </div>

                  <div className="flex flex-col mt-2 w-full">
                    <div className="relative w-full">
                      {fileDataURL && (
                        <div
                          className={`grid grid-cols-${
                            fileDataURL.length === 1 ? 1 : 2
                          }`}
                        >
                          <ImageViewer
                            fileDataURL={fileDataURL}
                            handleImages={handleImages}
                          />
                        </div>
                      )}

                      {fileDataURL && (
                        <div className="border absolute top-3 left-3 bg-gray-100 py-1 rounded-lg">
                          <label
                            htmlFor="dropzone-file"
                            className="flex items-center pr-8 pl-2 space-x-2 cursor-pointer"
                          >
                            <span>
                              <AiOutlinePlusSquare className="text-lg text-sw-100" />
                            </span>
                            <span>Add Photo</span>
                            <input
                              onChange={(e) => UploadFiles(e)}
                              accept="image/*"
                              id="dropzone-file"
                              type="file"
                              className="hidden"
                            />
                          </label>
                        </div>
                      )}
                    </div>

                    {!fileDataURL && (
                      <div className="border w-full flex justify-between items-center px-4 rounded-lg cursor-pointer">
                        <div>
                          <span className="text-lg font-semibold text-gray-400">
                            Upload File
                          </span>
                        </div>
                        <div>
                          <label
                            for="dropzone-file"
                            className="flex flex-col justify-center items-center w-[80px] mx-2 my-2 rounded-lg cursor-pointer"
                          >
                            <div className="flex flex-col justify-center items-center rounded-full p-1 hover:bg-gray-200/[0.6]">
                              <AiOutlineCloudUpload className="text-4xl text-sw-100" />
                            </div>
                            <input
                              onChange={(e) => UploadFiles(e)}
                              accept="image/*"
                              id="dropzone-file"
                              type="file"
                              className="hidden"
                            />
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* <div className="w-[50%] h-full">
                  <div className="flex flex-col h-full">
                    <NewRichTextEditor
                      placeholder={"Write text here"}
                      content={editorContent}
                      setContent={setEditorContent}
                    />
                  </div>
                </div> */}
              </div>

              <div className="flex justify-end items-center py-2 border-t h-[calc(10%)]">
                <div className="flex justify-between items-center space-x-2">
                  <button
                    type="reset"
                    onClick={() => setIsToggle(!isToggle)}
                    className="bg-gray-200 text-gray-800 font-semibold w-fit py-1 px-2 rounded-lg text-md"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="bg-green-800 text-white font-semibold w-fit py-1 px-2 rounded-lg text-md"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogModel;
