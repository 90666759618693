import React, { useEffect, useState } from "react";
import styles from "../services/services.module.css";
import ServicesModel from "../../components/models/ServicesModel";
import { BarChartBox } from "../../components/charts/Chart";
import { FaUsers } from "react-icons/fa";
import { Link } from "react-router-dom";
import { BsFillEyeFill } from "react-icons/bs";
import QueryView from "./QueryView";
import { UpdateRecord, getRecord } from "../../services/services.api";
import { useStateProvider } from "../../context/StateReducers";
import { reducerCases } from "../../context/constants";
import { MOMENT } from "../../utils/dateUtils";
import Pagination from "../../components/form/Pagination";
import { toastMessage } from "../../components/toast/toastMessage";
import { AiOutlineSearch } from "react-icons/ai";

const Query = () => {
  const [activeTab, setActiveTab] = useState("bussiness");
  const [searchQuery, setSearchQuery] = useState("");
  const tabs = ["bussiness", "services"];
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState([1]);

  const queries = {
    bussiness: [
      {
        id: 1,
        name: "John Doe",
        email: "john@example.com",
        query: "How do I reset my password?",
        date: "2024-01-01",
      },
      {
        id: 2,
        name: "Jane Smith",
        email: "jane@example.com",
        query: "Can I reschedule my appointment?",
        date: "2024-01-02",
      },
    ],
    services: [
      {
        id: 1,
        name: "Vendor 1",
        email: "vendor1@example.com",
        query: "What are the commission rates?",
        date: "2024-01-03",
      },
      {
        id: 2,
        name: "Vendor 2",
        email: "vendor2@example.com",
        query: "How do I list my services?",
        date: "2024-01-04",
      },
    ],
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  return (
    <div className="px-4 h-full overflow-y-auto no-scrollbar">
      {/* Header */}
      <h1 className="text-xl font-bold text-gray-700 mb-2">Queries</h1>

      {/* Tabs */}
      <div className="flex space-x-4 border-b mb-4">
        {tabs.map((tab, idx) => (
          <button
            key={idx}
            className={`px-4 py-2 font-semibold capitalize ${
              activeTab === tab
                ? "text-blue-600 border-b-2 border-blue-600"
                : "text-gray-600"
            }`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>

      {/* Search */}
      <div className="mb-4 flex justify-end">
        <input
          type="text"
          placeholder="Search queries..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="border rounded-lg px-4 py-2 w-full max-w-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      {/* Table */}
      <div className="overflow-x-auto">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-white uppercase bg-sw-100/[0.8]">
            <tr>
              <th scope="col" className="px-6 py-3">
                ID
              </th>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Email
              </th>
              <th scope="col" className="px-6 py-3">
                Query
              </th>
              <th scope="col" className="px-6 py-3">
                Date
              </th>
            </tr>
          </thead>
          <tbody>
            {queries[activeTab].length > 0 ? (
              queries[activeTab].map((item) => (
                <tr key={item.id} className="hover:bg-gray-100 even:bg-gray-50">
                  <td className="px-6 py-4 capitalize">{item.id}</td>
                  <td className="px-6 py-4 capitalize">{item.name}</td>
                  <td className="px-6 py-4 capitalize">{item.email}</td>
                  <td className="px-6 py-4 capitalize">{item.query}</td>
                  <td className="px-6 py-4 capitalize">{item.date}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="px-6 py-4 text-center text-gray-500">
                  No queries found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div>
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          limit={limit}
          setLimit={setLimit}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default Query;
