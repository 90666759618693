import React, { useEffect } from "react";
import { useState } from "react";
import Select from "react-select";

const MultiSelector = ({ services, selectedServices, setSelectedServices }) => {
  const handleChange = (selectedOption) => {
    console.log({ selectedOption });
    setSelectedServices(selectedOption);
  };

  return (
    <Select
      isMulti={true}
      value={selectedServices}
      onChange={(data) => handleChange(data)}
      options={services.map((option) => ({
        value: option.id,
        label: option.name,
      }))}
      isSearchable={true}
      placeholder="Select Services"
      className="p-1 rounded-md shadow-md capitalize focus:outline-none hover:cursor-pointer"
    />
  );
};

export default MultiSelector;
