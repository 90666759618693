import React, { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import styles from "../services/services.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";

import profileLogo from "../../assets/profile_logo.webp";
import { AiFillDelete, AiOutlineBlock } from "react-icons/ai";
import { MdBlock } from "react-icons/md";
import Pagination from "../../components/form/Pagination";
import { FiEdit } from "react-icons/fi";
import { BsFillEyeFill } from "react-icons/bs";
import { calculateTime } from "../../utils/dateUtils";

const UsersView = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(null);
  const [totalPages, setTotalPages] = useState(null);

  useEffect(() => {}, [page, limit, currentPage, totalPages]);

  /////////////////////////////// Dummy Data /////////////////////////////////////////

  const dummyData = {
    name: "Danya Garcia",
    image: null,
    username: "@Dnaya",
    email: "Ddaya21@gmail.com",
    contact: "8645789215",
    status: "Active",
    appointments: [
      {
        service: "Hair Coloring",
        date: "22-10-2023",
        time: "11:00 AM",
        location: "Vihar Nagar",
        status: "Active",
        amount: 250,
        vendor: "SalonWallah",
      },
      {
        service: "Hair Coloring",
        date: "22-10-2023",
        time: "11:00 AM",
        location: "Vihar Nagar",
        status: "Cancelled",
        amount: 250,
        vendor: "SalonWallah",
      },
      {
        service: "Hair Coloring",
        date: "22-10-2023",
        time: "11:00 AM",
        location: "Vihar Nagar",
        status: "Done",
        amount: 250,
        vendor: "SalonWallah",
      },
    ],
  };

  ////////////////////////////////////////////////////////////////////////////////////

  return (
    <div className="p-2">
      <div className="flex items-center justify-start space-x-4 border-b-2 py-3">
        <span onClick={() => navigate(-1)}>
          <BiArrowBack className="text-lg cursor-pointer font-bold" />
        </span>
        <p className="text-xs font-medium">
          <span className="bg-red-600 px-2 py-1 text-white rounded-lg capitalize">
            User
          </span>
          {" / "}
          <span className="bg-red-600 px-2 py-1 text-white rounded-lg capitalize">
            {dummyData?.name}
          </span>
        </p>
      </div>

      <div className="mt-2 grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 gap-2">
        <div className="col-span-1 p-1 rounded-lg shadow-md h-fit">
          <div className="flex justify-center items-center w-full px-16 sm:px-8 py-4 ">
            {dummyData?.image !== null ? (
              <img className="aspect-1 w-full h-full" src={dummyData?.image} />
            ) : (
              <img className="aspect-1 w-full h-full" src={profileLogo} />
            )}
          </div>
          <p className="text-center font-semibold text-md">{dummyData?.name}</p>

          <div className="flex justify-center my-2">
            <button className="bg-sw-100 text-sm text-white py-1 px-3 rounded-lg shadow-md cursor-pointer">
              BLOCK USER
            </button>
          </div>

          <div className="flex flex-col justify-start px-4">
            <span className="text-slate-600 text-sm font-medium">Details</span>
            <hr className="w-full py-1" />
            <p>
              <span className="text-sm">Name : </span>
              <span className="text-sm text-slate-600">{dummyData?.name}</span>
            </p>
            <p>
              <span className="text-sm">Username : </span>
              <span className="text-sm text-slate-600">
                {dummyData?.username}
              </span>
            </p>
            <p>
              <span className="text-sm">Email : </span>
              <span className="text-sm text-slate-600">{dummyData?.email}</span>
            </p>
            <p>
              <span className="text-sm">Contact : </span>
              <span className="text-sm text-slate-600">
                {dummyData?.contact}
              </span>
            </p>
            <p>
              <span className="text-sm">Status : </span>
              <span className="text-sm text-slate-600 capitalize">
                {dummyData?.status}
              </span>
            </p>
          </div>
        </div>
        <div className="col-span-1 sm:col-span-2 md:col-span-3 xl:col-span-4 p-1 rounded-lg shadow-md">
          <div className="flex justify-start items-center py-3 px-4 gap-4">
            <div>
              <h1 className="text-lg font-semibold">Appointments</h1>
            </div>
          </div>

          <div
            className={`h-[400px] overflow-y-auto cursor-pointer ${styles.editorContainer}`}
          >
            <table class="w-full text-sm text-left text-gray-500">
              <thead class="text-xs text-white uppercase bg-sw-100/[0.8]">
                <tr>
                  <th scope="col" class="px-6 py-3">
                    S.No
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Service
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Date
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Time
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Status
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Amount
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Location
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Vendor
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {dummyData &&
                  dummyData.appointments.map((item, index) => (
                    <tr
                      class={`${
                        (index + 1) % 2 == 0 ? "bg-gray-100" : "bg-gray-50"
                      }`}
                      key={index + 1}
                    >
                      <th
                        scope="row"
                        class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                      >
                        <span>{index + 1}</span>
                      </th>
                      <td class="px-6 py-4">{item?.service}</td>
                      <td class="px-6 py-4">{item?.date}</td>
                      <td class="px-6 py-4">{item?.time}</td>
                      <td class="px-6 py-4">
                        <span
                          className={`${
                            item?.status.toLowerCase() === "active"
                              ? "text-green-700"
                              : "text-sw-100"
                          } font-semibold capitalize`}
                        >
                          {item?.status}
                        </span>
                      </td>
                      <td class="px-6 py-4">{item?.amount}</td>
                      <td class="px-6 py-4">{item?.location}</td>
                      <td class="px-6 py-4">{item?.vendor}</td>
                      <td className="px-6 py- space-x-4">
                        <div className="flex space-x-2 justify-center">
                          <button
                            // onClick={() => editHandleClick(item?.id)}
                            class="font-medium"
                          >
                            <MdBlock className="text-md text-sw-100" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <div className="flex justify-between items-center py-2 px-4">
            <div></div>
            <div className="h-12 w-[300px] relative flex justify-between">
              <div></div>
              <div>
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  setPage={setPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersView;
