import React, { useEffect, useState } from "react";
import Pagination from "../../components/form/Pagination";
import { getRecord, UpdateRecord } from "../../services/services.api";
import { useStateProvider } from "../../context/StateReducers";
import { reducerCases } from "../../context/constants";
import { toastMessage } from "../../components/toast/toastMessage";

const Users = () => {
  const [activeTab, setActiveTab] = useState("users");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(null);
  const [totalPages, setTotalPages] = useState([]);
  const tabs = ["users", "vendors", "artists"];

  const [{ users, userInfo, refresh }, dispatch] = useStateProvider();

  useEffect(() => {
    callApi();
  }, [page, limit, userInfo, refresh, activeTab]);

  async function callApi() {
    if (userInfo) {
      let params = {};
      params.limit = limit;
      params.page = page;

      const userType =
        activeTab === "users"
          ? "users"
          : activeTab === "vendors"
          ? "vendor"
          : "artist";

      const { data } = await getRecord(
        `/admin/${userType}/list`,
        params,
        userInfo?.accessToken
      );

      if (data.success) {
        dispatch({
          type: reducerCases.GET_USERS,
          users: data.data.rows,
        });
        setCurrentPage(data.data.currentPage);
        setTotalPages(
          Array.from({ length: data.data.totalPages }, (_, index) => index + 1)
        );
        dispatch({ type: reducerCases.SET_REFRESH, refresh: false });
      }
    }
  }

  const handleBlockStatus = (e, id) => {
    let payload = {};
    console.log("e.target.checked", e.target.checked);
    if (e.target.checked) {
      payload.isBlock = true;
    } else {
      payload.isBlock = false;
    }
    (async function (id) {
      const { data } = await UpdateRecord(
        `/admin/update/${id}/status`,
        payload,
        userInfo?.accessToken
      );
      if (data.success) {
        dispatch({ type: reducerCases.SET_REFRESH, refresh: true });
        toastMessage(data);
      }
    })(id);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  return (
    <div className="px-4 h-full overflow-y-auto no-scrollbar">
      <h1 className="py-2 text-xl font-medium text-gray-600">Users</h1>

      {/* Tabs */}
      <div className="flex space-x-4 border-b mb-4">
        {tabs.map((tab, idx) => (
          <button
            key={idx}
            className={`px-4 py-2 font-semibold capitalize ${
              activeTab === tab
                ? "text-blue-600 border-b-2 border-blue-600"
                : "text-gray-600"
            }`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>

      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-lg font-medium text-gray-700 py-4">Filter:</h1>
        </div>
        <div className="w-[40%]">
          <input
            type="text"
            placeholder="Search..."
            className="border rounded-lg px-4 py-2 w-full focus:outline-none focus:ring-2 focus:ring-sw-100/[0.2]"
          />
        </div>
      </div>

      <div>
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-white uppercase bg-sw-100/[0.8]">
            <tr>
              <th scope="col" className="px-6 py-3">
                Photo
              </th>
              <th scope="col" className="px-6 py-3">
                Role
              </th>
              <th scope="col" className="px-6 py-3">
                Full Name
              </th>
              <th scope="col" className="px-6 py-3">
                Email Address
              </th>
              <th scope="col" className="px-6 py-3">
                Phone Number
              </th>
              <th scope="col" className="px-6 py-3">
                Status
              </th>
              <th scope="col" className="px-6 py-3">
                Block
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {users &&
              users.map((item, idx) => (
                <tr key={idx} className={`bg-gray-100 even:bg-gray-50`}>
                  <td
                    scope="col"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                  >
                    Photo
                  </td>
                  <td scope="col" className="px-6 py-4">
                    {item?.role}
                  </td>
                  <td scope="col" className="px-6 py-4">
                    {item?.firstName} {item?.lastName}
                  </td>
                  <td scope="col" className="px-6 py-4">
                    {item?.email}
                  </td>
                  <td scope="col" className="px-6 py-4">
                    {item?.phone}
                  </td>
                  <td scope="col" className="px-6 py-4">
                    <span className="capitalize">{item?.status}</span>
                  </td>
                  <td scope="col" className="px-6 py-4">
                    <span className="capitalize text-green-500 font-medium">
                      {item?.isBlock ? "Blocked" : "Unblocked"}
                    </span>
                  </td>
                  <td scope="col" className="px-6 py-4">
                    <div>
                      <label class="relative inline-flex items-center mr-5 cursor-pointer">
                        <input
                          type="checkbox"
                          value=""
                          class="sr-only peer"
                          onChange={(e) => handleBlockStatus(e, item?.id)}
                          checked={item?.isBlock === false ? false : true}
                        />
                        <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-red-300 dark:peer-focus:ring-red-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-red-600"></div>
                      </label>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <div>
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          limit={limit}
          setLimit={setLimit}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default Users;
